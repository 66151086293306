import { Component, OnInit } from '@angular/core';
import { HytekService } from '../../services/hytek.service';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { CommonModule } from '@angular/common';
import { FormsModule, NgModel } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { SupabaseService } from '../../../services/supabase/supabase.service';

@Component({
  selector: 'app-emails',
  standalone: true,
  imports: [MatFormFieldModule, MatSelectModule, FormsModule, CommonModule, MatButtonModule],
  templateUrl: './emails.component.html',
  styleUrls: ['./emails.component.css']
})
export class EmailsComponent{ /*implements OnInit {
  password: string = 'USATF2024'; // Set your desired password here
  enteredPassword: string = '';
  accessGranted: boolean = false;

  errorMessage: string | null = null;
  events: any;
  uniqueEmails: Set<string> = new Set();
  inputEmails: string = '';

  constructor(
    private hytekService: HytekService,
    private readonly supabase: SupabaseService
  ) { }

  ngOnInit() {
    this.loadEventsFromSupabase();
  }

  async loadEventsFromSupabase() {
    const { data: events, error } = await this.supabase.downloadEventRegistrations();
    if (error) {
      this.errorMessage = 'Failed to load events';
      return;
    }
    this.events = events;
    this.extractEmails(this.events);
  }

  extractEmails(eventsData: any[]) {
    this.uniqueEmails.clear(); // Clear the set before adding new emails

    eventsData.forEach(eventGroup => {
      eventGroup.events.forEach((event: any) => {
        event.athletes.forEach((athlete: any) => {
          const email = this.extractEmail(athlete.email);
          if (email) {
            this.uniqueEmails.add(email.toLowerCase());
          }
        });
        const email = this.extractEmail(eventGroup.email);
        if (email) {
          this.uniqueEmails.add(email.toLowerCase());
        }
      });
    });
  }

  extractEmail(emailString: string): string | null {
    if (!emailString) return null;
    const emailMatch = emailString.match(/<(.+)>/);
    return emailMatch ? emailMatch[1] : emailString.trim();
  }

  checkPassword() {
    if (this.enteredPassword === this.password) {
      this.accessGranted = true;
      this.errorMessage = null;
    } else {
      this.errorMessage = 'Invalid password';
    }
  }

  removeInputEmails() {
    const inputEmailSet = new Set(this.inputEmails.split('\n')
      .map(email => {
        const extractedEmail = this.extractEmail(email.trim().replace(/,$/, ''));
        return extractedEmail ? extractedEmail.toLowerCase() : '';
      })
      .filter(email => email)
    );
    inputEmailSet.forEach(email => this.uniqueEmails.delete(email));
  }

  get emailList(): string {
    return Array.from(this.uniqueEmails).join('\n');
  }

  get emailCount(): number {
    return this.uniqueEmails.size;
  }*/
}