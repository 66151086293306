import { Component, Input, inject, OnInit } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { AsyncPipe, CommonModule } from '@angular/common';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonModule } from '@angular/material/button';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatListModule } from '@angular/material/list';
import { MatIconModule } from '@angular/material/icon';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { RouterOutlet } from '@angular/router';
import { FooterComponent } from '../footer/footer.component';
import { UserCardComponent } from '../user-card/user-card.component';
import { ProgressBarComponent } from '../progress-bar/progress-bar.component';
import { AuthSession } from '@supabase/supabase-js'
import { AppComponent } from '../../../app.component';
import { ClipboardModule } from '@angular/cdk/clipboard';
import {MatBadgeModule} from '@angular/material/badge';
import{ NgcCookieConsentModule } from 'ngx-cookieconsent';
import { AuthenticationService } from '../../../services/authentication/authentication.service';
import { URLHandlerService } from '../../../services/urlhandler/urlhandler.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrl: './navigation.component.css',
  standalone: true,
  imports: [
    MatToolbarModule,
    MatButtonModule,
    MatSidenavModule,
    MatListModule,
    MatIconModule,
    AsyncPipe,
    RouterOutlet,
    FooterComponent,
    UserCardComponent,
    ProgressBarComponent,
    CommonModule,
    AppComponent,
    ClipboardModule,
    MatBadgeModule,
    NgcCookieConsentModule,
  ]
})
export class NavigationComponent implements OnInit {
  hidden = false
  copied = false
  userId = ''
  loading = false
  testV = "bad"
  userIDBase64 = ""
  userLink = ""
  userURL = ""
  @Input()
  session!: AuthSession

  private breakpointObserver = inject(BreakpointObserver);
  constructor(private readonly authenticationService: AuthenticationService, 
    private readonly URLHandler: URLHandlerService, private _snackBar: MatSnackBar, 
  ) { }

  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
    .pipe(
      map(result => result.matches),
      shareReplay()
    );


  async ngOnInit(): Promise<void> {

    //this.supabase.authChanges((_, session) => (this.session = session))
    // await this.getSession()
    if (this.session) {
      await this.getUserID()
    }


    await this.URLHandler.hexToBase64(this.userId).then(res => {
      this.userIDBase64 = res;
    })

    await this.URLHandler.base64ToAthleteLink(this.userIDBase64).then(res => {
      this.userLink = res;
    })

    await this.URLHandler.linkToURL(this.userLink).then(res => {
      this.userURL = res;
    })


  }

  async onCopy() {
    this._snackBar.open('Profile Link Copied!', 'Dismiss');
  }


  async getUserID() {
    try {
      this.loading = true
      this.testV = "good"
      const userID = this.session.user.id //switch to public id maybe add qr code
      if (userID) {
        this.testV = "vgood"
        this.userId = userID
      }
    } catch (error) {
      if (error instanceof Error) {
        alert(error.message)
      }

    } finally {
      this.loading = false
    }
  }

  async signOut() {
    await this.authenticationService.signOut()
  }
}

