import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

export interface Meet {
  meet_id: string;
  title: string;
  description: string;
  location: string;
  date: string;
  created_by: string;
  created_at: string;
  updated_at: string;
}

export interface MeetDTO {
  title: string;
  description: string;
  location: string;
  date: string;
  isFlatFee: boolean;
  flatFeeAmount?: number;
}

@Injectable({
  providedIn: 'root'
})
export class MeetService {

  private apiUrl = '/api/meets';

  constructor(private http: HttpClient) { }

  createMeet(meet: MeetDTO): Observable<Meet> {
    return this.http.post<Meet>(this.apiUrl, meet);
  }

  getAllMeets(): Observable<Meet[]> {
    return this.http.get<Meet[]>(this.apiUrl);
  }

  getMeetById(meetId: string): Observable<Meet> {
    return this.http.get<Meet>(`${this.apiUrl}/${meetId}`);
  }

  updateMeet(meetId: string, meet: MeetDTO): Observable<Meet> {
    return this.http.put<Meet>(`${this.apiUrl}/${meetId}`, meet);
  }

  deleteMeet(meetId: string): Observable<any> {
    return this.http.delete(`${this.apiUrl}/${meetId}`);
  }
}