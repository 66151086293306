import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { CommonModule, NgStyle, } from '@angular/common';
import { SafeResourceUrl, DomSanitizer } from '@angular/platform-browser'
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';
import { A11yModule } from '@angular/cdk/a11y'
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { AuthSession } from '@supabase/supabase-js';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatBadgeModule } from '@angular/material/badge';
import { MatCardModule } from '@angular/material/card';
import { MatDialog } from '@angular/material/dialog';
import { EditCardDataComponent } from '../edit-card-data/edit-card-data.component';
import { AuthenticationService } from '../../../services/authentication/authentication.service';
import { AthleteService } from '../../../services/athlete/athlete.service';
import { CardDisplay } from '../../../models/athlete/athlete.model';
import { ImageService } from '../../../services/image/image.service';
import { FormsModule } from '@angular/forms';
import { ImageCropperComponent } from '../image-cropper/image-cropper.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { URLHandlerService } from '../../../services/urlhandler/urlhandler.service';


@Component({
  selector: 'app-user-card',
  standalone: true,
  imports: [
    MatFormFieldModule,
    MatInputModule,
    CommonModule,
    MatIconModule,
    MatButtonModule,
    MatMenuModule,
    A11yModule,
    MatBadgeModule,
    MatCardModule,
    FormsModule,
    ImageCropperComponent,
    MatProgressSpinnerModule
  ],
  templateUrl: './user-card.component.html',
  styleUrl: './user-card.component.css'
})
export class UserCardComponent implements OnInit {
  hidden = false
  userIDBase64 = ""
  userLink = ""
  userURL = ""
  session = this.authenticationService.session
  _cardPhotoUrl: SafeResourceUrl | undefined
  loading = false
  cardBack = false
  cardDisplay!: CardDisplay
  commentator = true
  notes = false
  editable = false
  editing: boolean[] = [false, false, false, false, false, false, false, false, false]
  placeholder: string[] = [' ', ' ', ' ', ' ', ' ', ' ', ' ', ' ', ' ']
  cardValue: string[] = ['name', 'pronunciation', 'nickname', 'notes']
  editID = ""
  section="front";
  music=false
  isUploading: boolean = false;

  imageUrl: any = '';


  // State to toggle image editing
  isEditingImage: boolean = false;

  // Default avatar path
  defaultAvatar = '../../../../assets/images/profilePhoto.jpg';

  // Reference to ImageCropperComponent
  @ViewChild('imageCropper') imageCropper!: ImageCropperComponent;

  @Input() userID!: string
  @Input() authSession?: AuthSession
  @Input() sessionSent?: boolean
  @Input() tempID!: string

  //FIgure out URL by user ID!
  /*
  set cardPhotoUrl(url: string | null) {
    if (url) {
      this.downloadImage(url)
    }
  }
*/

  constructor(
    private readonly authenticationService: AuthenticationService,
    private readonly athleteService: AthleteService, 
    private readonly dom: DomSanitizer, 
    private _snackBar: MatSnackBar, 
    private readonly URLHandler: URLHandlerService, 
    public dialog: MatDialog, // Add MatDialog to the constructor
    private readonly imageService: ImageService // Inject ImageService
  ) { }
  
  async ngOnInit(): Promise<void> {
    if (this.userID) {
      await this.getCardData()
      await this.getEditable()
      await this.loadCardPhoto();
      await this.URLHandler.hexToBase64(this.userID).then(res => {
        this.userIDBase64 = res;
      })
      await this.URLHandler.base64ToAthleteLink(this.userIDBase64).then(res => {
        this.userLink = res;
      })
    } else if (this.tempID) {
      await this.getTempData()
      await this.getEditable()
      this.loadCardPhoto();
    }
  // Load the card photo initially
  
    //const { name, pronunciation, nickname, line1, line2, notes } = this.cardDisplay //add url to cardDIsplay
    //this.downloadImage("http://localhost:4200/0df61572-05d5-4745-b965-b34cf7cc75df")
  }

  async getCardData() {
    try {
      this.loading = true
      const { data: cardDisplay, error, status } = await this.athleteService.getCardDisplay(this.userID)

      if (error && status !== 406) {
        throw error
      }

      if (cardDisplay) {
        this.cardDisplay = cardDisplay
      }
    } catch (error) {
      if (error instanceof Error) {
        alert(error.message)
      }
    } finally {
      this.loading = false
    }
  }

  async getTempData() {
    try {
      this.loading = true
      const { data: cardDisplay, error, status } = await this.athleteService.getCardTempDisplay(this.tempID)

      if (error && status !== 406) {
        throw error
      }

      if (cardDisplay) {
        this.cardDisplay = cardDisplay
      }
    } catch (error) {
      if (error instanceof Error) {
        alert(error.message)
      }
    } finally {
      this.loading = false
    }
  }

  async getEditable() { //this is uggly TODO

    if (this.userID) {
      if (!this.sessionSent) {
        if (this.session?.user.id.replace(/[-]/g, "") == this.userID.replace(/[-]/g, "") || this.session?.user.id.replace(/[-]/g, "") == "0daed35ce08e44639134111315626bb1") {
          this.editable = true
        }
      }
      if (this.sessionSent) {
        if (this.authSession?.user.id.replace(/[-]/g, "") == this.userID.replace(/[-]/g, "") || this.authSession?.user.id.replace(/[-]/g, "") == "0daed35ce08e44639134111315626bb1") {
          this.editable = true
        }
      }
    }

    else if (this.tempID) {
      if (!this.sessionSent) {
        if (this.session?.user.id.replace(/[-]/g, "") == "0daed35ce08e44639134111315626bb1") {
          this.editable = true
        }
      }
      if (this.sessionSent) {
        if (this.authSession?.user.id.replace(/[-]/g, "") == "0daed35ce08e44639134111315626bb1") {
          this.editable = true
        }
      }
    }

    if (this.editable) {
      this.placeholder = ['Name', 'Pronunciation', 'Nickname', 'Event 1 PR: SB:', 'Event 2 PR: SB:', 'Team', 'Mascot', 'Coach', '2020 Olympic Qualifier looking to run under 3:55 today']
      if (this.userID) {
        this.editID = this.userID
      } else if (this.tempID) {
        this.editID = this.tempID
      }
    } else if (!this.editable) {
      this.placeholder = [' ', ' ', ' ', ' ', ' ', ' ', ' ', ' ', ' ']

    }
  }

  /*async downloadImage(path: string) {
    try {
      const { data } = await this.supabase.downloadCardPhoto(path)
      if (data instanceof Blob) {
        this._cardPhotoUrl = this.dom.bypassSecurityTrustResourceUrl(URL.createObjectURL(data))
      }
    } catch (error) {
      if (error instanceof Error) {
        console.error('Error downloading image: ', error.message)
      }
    }
  }*/


  async save(value: number, newValue: string): Promise<void> {

    this.loading = true
    //const { user } = this.session!
    try {
      let user = false
      if (this.userID) {
        user = true
      }
      const { error } = await this.athleteService.updateCardDataFromCard(
        user,
        this.editID,
        value,
        newValue
      )
      if (error) throw error

    } catch (error) {
      if (error instanceof Error) {
        alert(error.message)
      }
    } finally {
      this.openSnackBar("update saved", "dismiss")
      this.loading = false
    }




    /*
          const { error } = await this.supabase.updateCardData({
            id: user.id,
            name,
            pronunciation,
            nickname,
            line1,
            line2,
            notes,
          })
          if (error) throw error
        } catch (error) {
          if (error instanceof Error) {
            alert(error.message)
          }*/

  }

  openEditDialog(): void {
    this.cardDisplay.id=this.userID
    const dialogRef = this.dialog.open(EditCardDataComponent, {
      width: '390px',
      maxHeight: '100vh',
      minWidth: '390px',
      data: { cardDisplay: this.cardDisplay, section: this.section }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.saveCardData(result);
      }
    });
  }

  async saveCardData(updatedCardDisplay: CardDisplay) {

     try {
      this.loading = true
     

      const name = updatedCardDisplay.name as string
      const pronunciation = updatedCardDisplay.pronunciation as string
      const nickname = updatedCardDisplay.nickname as string
      const line1 = updatedCardDisplay.line1 as string
      const line2 = updatedCardDisplay.line2 as string
      const notes = updatedCardDisplay.notes as string
      const team_name = updatedCardDisplay.team_name as string
      const team_mascot = updatedCardDisplay.team_mascot as string
      const coach = updatedCardDisplay.coach as string
      const imageUrl = updatedCardDisplay.imageUrl as string
     

      const { error } = await this.athleteService.updateCardDisplayData({
        id: updatedCardDisplay.id,
        name,
        pronunciation,
        nickname,
        line1,
        line2,
        notes,
        team_name,
        team_mascot,
        coach,
        imageUrl
      })

      if (error) throw error
    } catch (error) {
      if (error instanceof Error) {
        alert(error.message)
      }
    } finally {
      this.loading = false
    }
  }

 
  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action);
  }
  async reloadData() {
    this.cardValue = []
    await this.getCardData()
  }

  // Handle file input change event
  fileChangeEvent(event: any): void {
    const file = event.target.files[0];
    if (file) {
      this.imageUrl = URL.createObjectURL(file);
      this.isEditingImage = true;
    }
  }
  loadCardPhoto() {
    if (this.cardDisplay && this.cardDisplay.imageUrl) {
     
      this._cardPhotoUrl = this.dom.bypassSecurityTrustResourceUrl(this.cardDisplay.imageUrl
        //this.imageService.getImageUrl(this.cardDisplay.imageUrl)
      );
    } else {
      this._cardPhotoUrl = this.dom.bypassSecurityTrustResourceUrl(this.defaultAvatar);
    }
  }

  onImageError(event: Event) {
    const target = event.target as HTMLImageElement;
    target.src = this.defaultAvatar; // Replace with your default avatar URL
  }
/*

  onFileSelected(event: any): void {
    const file = event.target.files[0];
    if (file) {
      this.imageUrl = URL.createObjectURL(file);
      this.isEditingImage = true;
    }
  }*/


  //////////////////////////////////////////
   /**
   * Toggle the image editing mode.
   */
   toggleImageEdit() {
    this.isEditingImage = !this.isEditingImage;
    if (!this.isEditingImage) {
      // Reset any temporary data
      this.imageUrl = '';
    }
  }

  /**
   * Handle the cropped image file emitted from ImageCropperComponent.
   * @param file The cropped image file.
   */
  onImageCropped(file: File) {
    this.uploadCroppedImage(file);
  }

  /**
   * Handle the cropping cancellation event emitted from ImageCropperComponent.
   */
  onCroppingCanceled() {
    this.isEditingImage = false;
    this.imageUrl = '';
  }

  /**
   * Upload the cropped image using ImageService.
   * @param file The cropped image file.
   */
  uploadCroppedImage(file: File) {
    this.imageService.uploadImage(file).subscribe({
      next: (uploadedUrl) => {
        this._cardPhotoUrl = this.dom.bypassSecurityTrustResourceUrl(uploadedUrl);
        this.updateImageUrlInDatabase(uploadedUrl);
        this.isEditingImage = false;
        this.isUploading = false; // Reset loading state
      },
      error: (err) => {
        console.error('Upload error:', err);
        this.isUploading = false; // Reset loading state
        this.openSnackBar('Failed to upload image', 'Dismiss');
      },
    });
  }

  /**
   * Update the image URL in the database.
   * @param uploadedUrl The URL of the uploaded image.
   */
  updateImageUrlInDatabase(uploadedUrl: string) {
    this.athleteService.updateCardPhotoUrl(this.userID, uploadedUrl).then(() => {
      // Handle success if needed
      this.openSnackBar('Image updated successfully', 'Dismiss');
    }).catch((error) => {
      console.error('Error updating image URL:', error);
      this.openSnackBar('Failed to update image', 'Dismiss');
    });
  }


  /**
   * Save button handler to crop the image.
   */
  onSaveCrop() {
    if (this.isUploading) {
      // Prevent multiple uploads
      return;
    }
    if (this.imageCropper) {
      this.isUploading = true; // Set loading state
      this.imageCropper.cropImage();
    }
  }

  /**
   * Cancel button handler to cancel cropping.
   */
  onCancelCrop() {
    if (this.imageCropper) {
      this.imageCropper.cancelCrop();
    }
    this.isUploading = false; // Reset loading state
  }
}