<!DOCTYPE html>
<html lang="en">

<head>
    <meta charset="utf-8" />
    <title>Accept a payment</title>
    <meta name="description" content="A demo of a payment on Stripe" />
    <meta name="viewport" content="width=device-width, initial-scale=1" />
</head>

<body>
    <div class="container">
        <!-- Display a payment form -->
        @if(isLoading){
           <div class="loading-indicator">
            <mat-spinner></mat-spinner>
            <p>Loading checkout...</p>
           </div>
        }
        <div id="checkout">
            <!-- Checkout will insert the payment form here -->
        </div>

    </div>
</body>

</html>