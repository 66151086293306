<h1 mat-dialog-title>Edit Card</h1>
<div class="content" mat-dialog-content>
  <ng-container *ngIf="data.section === 'front'">
    <mat-form-field appearance="fill">
      <mat-label>Name</mat-label>
      <input matInput [(ngModel)]="data.cardDisplay.name">
    </mat-form-field>
    <mat-form-field appearance="fill">
      <mat-label>Pronunciation</mat-label>
      <input matInput [(ngModel)]="data.cardDisplay.pronunciation">
    </mat-form-field>
    <mat-form-field appearance="fill">
      <mat-label>Nickname</mat-label>
      <input matInput [(ngModel)]="data.cardDisplay.nickname">
    </mat-form-field>
    <mat-form-field appearance="fill">
      <mat-label>Event 1</mat-label>
      <input matInput [(ngModel)]="data.cardDisplay.line1">
      <mat-hint>E.g. 800m PR: 1:50.56 SB 1:52.64</mat-hint>
    </mat-form-field>
    <mat-form-field appearance="fill">
      <mat-label>Event 2</mat-label>
      <input matInput [(ngModel)]="data.cardDisplay.line2">
      <mat-hint>E.g. Shot Put PR: 23.56 m</mat-hint>
    </mat-form-field>
    <mat-form-field appearance="fill">
      <mat-label>Team</mat-label>
      <input matInput [(ngModel)]="data.cardDisplay.team_name">
    </mat-form-field>
    <mat-form-field appearance="fill">
      <mat-label>Mascot</mat-label>
      <input matInput [(ngModel)]="data.cardDisplay.team_mascot">
    </mat-form-field>
    <mat-form-field appearance="fill">
      <mat-label>Coach</mat-label>
      <input matInput [(ngModel)]="data.cardDisplay.coach">
    </mat-form-field>
  </ng-container>

  <ng-container *ngIf="data.section === 'back'">
    <mat-form-field appearance="fill" class="full-height full-width">
      <mat-label>Notes</mat-label>
      <textarea class="text-box" matInput [(ngModel)]="data.cardDisplay.notes" cdkTextareaAutosize
      #autosize="cdkTextareaAutosize"
      cdkAutosizeMinRows="5"
      cdkAutosizeMaxRows="10"></textarea>
      <mat-hint>Anything You Want the Fans To Know!</mat-hint>
    </mat-form-field>
  </ng-container>

  <ng-container *ngIf="data.section === 'links'">
    <mat-form-field appearance="fill">
      <mat-label>Website</mat-label>
      <input matInput [(ngModel)]="data.cardDisplay.notes">
    </mat-form-field>
    <mat-form-field appearance="fill">
      <mat-label>Social Media</mat-label>
      <input matInput [(ngModel)]="data.cardDisplay.notes">
    </mat-form-field>
  </ng-container>

  <ng-container *ngIf="data.section === 'gear'">
    <mat-form-field appearance="fill">
      <mat-label>Gear Type</mat-label>
      <input matInput [(ngModel)]="data.cardDisplay.notes">
    </mat-form-field>
    <mat-form-field appearance="fill">
      <mat-label>Gear Brand</mat-label>
      <input matInput [(ngModel)]="data.cardDisplay.notes">
    </mat-form-field>
  </ng-container>

  <ng-container *ngIf="data.section === 'calendar'">
    <mat-form-field appearance="fill">
      <mat-label>Event Date</mat-label>
      <input matInput [(ngModel)]="data.cardDisplay.notes">
    </mat-form-field>
    <mat-form-field appearance="fill">
      <mat-label>Event Location</mat-label>
      <input matInput [(ngModel)]="data.cardDisplay.notes">
    </mat-form-field>
  </ng-container>

  <ng-container *ngIf="data.section === 'music'">
  <app-song-search></app-song-search>
</ng-container>

</div>
<div mat-dialog-actions>
  <button class="button" mat-raised-button color="primary" (click)="onSave()">Save</button>
  <button class="button" mat-raised-button color="primary" (click)="onCancel()">Cancel</button>
</div>