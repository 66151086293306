<html>

<header>
    <h1> {{athlete.name}} </h1>
    @if(athlete.nickname){
    <h2>"{{athlete.nickname}}"</h2>
    }
</header>

<body>

    <mat-tab-group class="tabs" dynamicHeight mat-stretch-tabs="false" mat-align-tabs="center">

        <mat-tab class="tab">
            <ng-template mat-tab-label>
                <a class="label"> Trading Card</a> 
            </ng-template>
            @if(session){
                <app-user-card class="card" [sessionSent]=true [authSession]="session" [userID]="userID"></app-user-card>
            }@else {
               <app-user-card class="card" [sessionSent]=false [userID]="userID"></app-user-card> 
            }
            
        </mat-tab>

        <!--<mat-tab label="Follow {{athlete.name}}">-->
        <mat-tab class="tab">
            <ng-template mat-tab-label>
               <a class="label"> Follow {{athlete.name}}</a>
            </ng-template>
            <div class="buttons">
                @if(athlete.strava){<a mat-raised-button href="{{athlete.strava}}" target="_blank" color="primary">Strava</a>}
                @if(athlete.instagram){ <a mat-raised-button href="{{athlete.instagram}}" target="_blank" color="primary">Instagram</a>}
            </div>
        </mat-tab>
        <mat-tab class="tab">
            <ng-template mat-tab-label>
                <a class="label"> See External Results</a> 
            </ng-template>
            <div class="buttons">
                @if(athlete.tfrrs){<a mat-raised-button href="{{athlete.tfrrs}}" target="_blank" color="primary">TFRRS</a>}
                @if(athlete.world_athletics){<a mat-raised-button href="{{athlete.world_athletics}}" target="_blank" color="primary">World Athletics</a>}
                @if(athlete.milesplit){<a mat-raised-button href="{{athlete.milesplit}}" target="_blank" color="primary">MileSplit</a>}
                
            </div>
        </mat-tab>
    </mat-tab-group>


    <!--TODO Add a method to retrieve profile data-->
</body>

</html>