
 <div class="meet-info">
   @if(!buyTickets){
    <h1>{{ meetInfo.title }} Draft</h1>
    <p><strong>Dates:</strong> {{ meetInfo.dates }}</p>
    <p><strong>Location:</strong> {{ meetInfo.location }}</p>
    <p><strong>Hosted by:</strong> {{ meetInfo.host }}</p>
  
    <h2>Admission</h2>
    <p><strong>Daily:</strong> {{ meetInfo.admission.daily }}</p>
    <p><strong>3-Day Pass:</strong> {{ meetInfo.admission.pass }}</p>
    <p><strong>Free:</strong> {{ meetInfo.admission.free }}</p>
    <p><button mat-raised-button color="primary" class="ticket-button" (click)="buyTickets=true">Buy Tickets
      </button></p>

   <h2>Results</h2>
   <p><a mat-raised-button color="primary" class="ticket-button" href="http://live.vipertiming.com/timers/273">Viper Timing
  </a></p>

    <h2>Meet Information</h2>
    <p><a mat-raised-button color="primary" class="ticket-button" href="https://newjersey.usatf.org/programs/youth">Meet Schedule
    </a></p>
    <p><a mat-raised-button color="primary" class="ticket-button" href="https://newjersey.usatf.org/news/2021/usatf-region-2-junior-olympic-track-field-champion">Meet Passport
    </a></p>
  
    <h2>Packet Pickup</h2>
    <ul>
      <li *ngFor="let packet of meetInfo.packetPickup">
        <strong>{{ packet.date }} {{ packet.time }}:</strong> {{ packet.location }}
      </li>
    </ul>

  <h2>Host Hotel</h2>
  <p>{{ meetInfo.hotelInfo }}</p>
   }@else {
    <app-ticketing></app-ticketing>
   }
  </div>