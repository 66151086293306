import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';

@Injectable({
  providedIn: 'root'
})
export class HytekService {
  private eventCodes: { [key: string]: string } = {
    '400mH': '400H',
    '5 and Under 50m': '50',
    '50m': '50',
    '100m': '100',
    '1500m': '1500',
    '400m': '400',
    '100mH/110mH': '', // Special case, handled separately
    '800m': '800',
    '200m': '200',
    '1500RW': '1500RW',
    'Triple Jump': 'TJ',
    'Long Jump': 'LJ',
    'Shot Put': 'SP',
    '4x400m': '4x400',
    'Mile': '1MILE',
    'SMR(2 2 4 8)': 'SMR',
    '1600mRW': '1600RW',
    '4x100m': '4x100',
    'Swedish Relay(1 2 3 4)': 'SR',
    'Mixed 4x400(2 Boys, 2 Girls)': 'M4x400'
  };

  private nameSuffixes = ['Jr.', 'Sr.', 'II', 'III', 'IV'];

  private divisions = [
    { code: 'PM', name: '5 & Under Division', start: new Date('2018-01-02'), end: new Date('2024-05-21'), div: 1 },
    { code: 'SB', name: '6-8 Division', start: new Date('2015-01-01'), end: new Date('2017-12-31'), div: 2 },
    { code: 'BN', name: '9-10 Division', start: new Date('2013-01-01'), end: new Date('2014-12-31'), div: 3 },
    { code: 'MI', name: '11-12 Division', start: new Date('2011-01-01'), end: new Date('2012-12-31'), div: 4 },
    { code: 'YH', name: '13-14 Division', start: new Date('2009-01-01'), end: new Date('2010-12-31'), div: 5 },
    { code: 'IM', name: '15-16 Division', start: new Date('2007-01-01'), end: new Date('2008-12-31'), div: 6 },
    { code: 'YG', name: '17-18 Division', start: new Date('2004-05-22'), end: new Date('2006-12-31'), div: 7 },
    { code: 'OM', name: 'Open & Masters', start: new Date('1924-01-01'), end: new Date('2004-12-31'), div: 8 }
  ];

  createIRecord(entry: any): string {
    const fields = [
      'I',
      entry.lastName,
      entry.firstName,
      entry.initial || '',
      entry.gender.charAt(0).toUpperCase(),
      entry.birthDate ? this.formatDate(entry.birthDate) : '',
      entry.teamCode || 'UNA',
      entry.teamName || 'Unattached',
      entry.age || '',
      entry.schoolYear || '',
      entry.address1 || '',
      entry.address2 || '',
      entry.city || '',
      entry.state || '',
      entry.zip || '',
      entry.country || '',
      entry.citizenCountry || '',
      entry.homePhone || '',
      entry.officePhone || '',
      entry.fax || '',
      entry.shirtSize || '',
      entry.registrationNumber || '',
      '',
      entry.email || '',
      //entry.disabledClassification || ''
    ];
    return fields.join(';');
  }

  createDRecord(entry: any): string {
    const fields = [
      'D',
      entry.lastName,
      entry.firstName,
      entry.initial || '',
      entry.gender.charAt(0).toUpperCase(),
      entry.birthDate ? this.formatDate(entry.birthDate) : '',
      entry.teamCode || 'UNA',
      entry.teamName || 'Unattached',
      entry.age || '',
      entry.schoolYear || '',
      entry.eventCode,
      entry.entryMark,
      entry.eventMeasure,
      entry.eventDivision || '',
      '',
      entry.finishPlace || '',
      entry.declarationStatus || '',
      entry.entryNote || '',
      //'',
      //entry.alternate ? 'Y' : ''
    ];
    return fields.join(';');
  }

  private formatDate(date: string): string {
    const d = new Date(date);
    const month = ('0' + (d.getMonth() + 1)).slice(-2);
    const day = ('0' + d.getDate()).slice(-2);
    const year = d.getFullYear();
    return `${month}/${day}/${year}`;
  }

  private extractLastName(fullName: string): string {
    const names = fullName.trim().split(' ');
    if (this.nameSuffixes.includes(names[names.length - 1])) {
      return names.slice(-2).join(' ');
    }
    return names.slice(-1)[0];
  }

  private extractFirstName(fullName: string): string {
    const names = fullName.trim().split(' ');
    if (this.nameSuffixes.includes(names[names.length - 1])) {
      return names.slice(0, -2).join(' ');
    }
    return names.slice(0, -1).join(' ');
  }

  private getEventCode(eventName: string, gender: string, division: number): string {
    if (eventName === '100mH/110mH') {
      if(division<5){
          return '80H';
      }
      else{
        return gender.toUpperCase() === 'F' ? '100H' : '110H';
      }
    }
    return this.eventCodes[eventName] || eventName;
  }

  private generateCompetitorNumber(athlete: any): string {
    const dataString = `${athlete.name}${athlete.dob}${athlete.gender}${athlete.team}`;
    return CryptoJS.SHA256(dataString).toString(CryptoJS.enc.Hex).substring(0, 5);
  }

  private getTeamCode(teamName: string): string {
    if (teamName.toLowerCase().includes('unattached') || teamName.toLowerCase().includes('unaffiliated')|| teamName.toLowerCase().includes('n/a')) {
      return 'UNA';
    }
    const hash = CryptoJS.SHA256(teamName.trim()).toString(CryptoJS.enc.Hex);
    return hash.substring(0, 4).toUpperCase();
  }

  private getDivision(birthDate: string): number {
    const birth = new Date(birthDate);
    for (const division of this.divisions) {
      if (birth >= division.start && birth <= division.end) {
        return division.div;
      }
    }
    return 0; // Default to 0 if no division matches
  }

  convertJsonToDelimitedByDate(json: any[], selectedDate: string): string {
    let delimitedString = '';
    const iRecords: { [key: string]: string } = {};
    const dRecords: string[] = [];

    json.forEach(event => {
      if (event.date.startsWith(selectedDate)) {
        event.athletes.forEach((athlete: any) => {
          // Clean up team name
          athlete.team = athlete.team.trim();
          if (athlete.team.toLowerCase() === 'unaffiliated' || athlete.team.toLowerCase() === 'unattached '|| athlete.team.toLowerCase() === 'n/a') {
            athlete.team = 'Unattached';
          }

          const commonEntry = {
            lastName: this.extractLastName(athlete.name),
            firstName: this.extractFirstName(athlete.name),
            initial: '',
            gender: athlete.gender,
            birthDate: athlete.dob,
            teamCode: this.getTeamCode(athlete.team),
            teamName: athlete.team,
            competitorNumber: this.generateCompetitorNumber(athlete),
            email: athlete.email || '',
            eventCode: this.getEventCode(event.name, athlete.gender, this.getDivision(athlete.dob)),
            entryMark: athlete.seedMark,
            eventMeasure: 'M',
            declarationStatus: '',
            alternate: false,
            eventDivision: this.getDivision(athlete.dob)
          };

          // Create I record entry only once per athlete
          const athleteId = athlete.id;
          if (!iRecords[athleteId]) {
            const iRecordEntry = {
              ...commonEntry,
              address1: athlete.address1 || '',
              address2: athlete.address2 || '',
              city: athlete.city || '',
              state: athlete.state || '',
              zip: athlete.zip || '',
              country: athlete.country || '',
              citizenCountry: athlete.citizenCountry || '',
              homePhone: athlete.homePhone || '',
              officePhone: athlete.officePhone || '',
              fax: athlete.fax || '',
              shirtSize: athlete.shirtSize || '',
              registrationNumber: athlete.registrationNumber || '',
              disabledClassification: athlete.disabledClassification || ''
            };
            iRecords[athleteId] = this.createIRecord(iRecordEntry);
          }

          // Create D record entry
          const dRecordEntry = {
            ...commonEntry,
            schoolYear: athlete.schoolYear || '',
            finishPlace: athlete.finishPlace || '',
            entryNote: athlete.entryNote || ''
          };
          dRecords.push(this.createDRecord(dRecordEntry));
        });
      }
    });

    // Add I records to delimited string
    Object.values(iRecords).forEach(iRecord => {
      delimitedString += iRecord + '\r\n';
    });

    // Add D records to delimited string
    dRecords.forEach(dRecord => {
      delimitedString += dRecord + '\r\n';
    });

    return delimitedString.trim(); // Remove the last newline character
  }
}
