import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, inject } from '@angular/core';
import { MosaicdashService, STRIPE_PUBLIC_KEY } from '../../services/mosaicdash.service';
import { StripeElementsDirective, StripePaymentElementComponent, StripeService, injectStripe } from 'ngx-stripe';
import { StripeEmbeddedCheckout, StripeEmbeddedCheckoutOptions, loadStripe } from '@stripe/stripe-js';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatInputModule } from '@angular/material/input';
import { MatDividerModule } from '@angular/material/divider';
import { MatDialogModule } from '@angular/material/dialog';
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import { ReactiveFormsModule } from '@angular/forms';
import { CurrencyPipe } from '@angular/common';
import { MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import { SupabaseService } from '../../../services/supabase/supabase.service';
import { RegistrationService } from '../../../services/registration/registration.service';

@Component({
  selector: 'app-embedded-checkout',
  standalone: true,
  imports: [CurrencyPipe,
    ReactiveFormsModule,
    MatButtonModule,
    MatCardModule,
    MatDialogModule,
    MatDividerModule,
    MatInputModule,
    MatToolbarModule,
    MatProgressSpinnerModule,
  ],
  templateUrl: './embedded-checkout.component.html',
  styleUrl: './embedded-checkout.component.css'
})
export class EmbeddedCheckoutComponent implements OnInit, OnDestroy {
  
  constructor(private readonly supabase: SupabaseService,
    private readonly registrationService: RegistrationService
  ) {}
  checkedout=false;
  @Input() data: any[] = [];
  @Output() checkedoutChange = new EventEmitter<boolean>();

  isLoading = true;

  allEvents: any[] = [];
  allRelayEvents: any[] = [];
  registrar: string[] = [];

  private readonly mosaicdashService = inject(MosaicdashService);
  readonly stripe = injectStripe(STRIPE_PUBLIC_KEY);

  checkout!: StripeEmbeddedCheckout
  checkoutOptions: StripeEmbeddedCheckoutOptions = {
    onComplete:()=> this.saveEntriesToSupabase()
  }

  

   ngOnInit() {
    this.data.forEach(item => {
      this.allEvents = this.allEvents.concat(item.events);
      this.allRelayEvents = this.allRelayEvents.concat(item.relayEvents);
    });
   this.registrar= this.data[0].registrar
   this.mountChekout();
  }

  ngOnDestroy() {
    if (this.checkout) {
      this.checkout.destroy();
    }
  }

  mountChekout(){
    const items = JSON.stringify(this.data)

    this.mosaicdashService
      .createCheckoutSession({
        items,
      }).subscribe((pi) => {
        this.checkoutOptions.clientSecret = pi.client_secret as string;

        this.stripe.initEmbeddedCheckout(this.checkoutOptions).subscribe(checkout => {
           this.isLoading= false;
          this.checkout = checkout;           
         

          this.checkout.mount('#checkout');
        })
       
      });
    }

   

   async saveEntriesToSupabase(){
    try {
  
      
      // Call the Supabase service with the extracted data
      const { error } = await this.registrationService.saveEventRegistration(this.allEvents, this.allRelayEvents, this.registrar);
      console.log('Entries saved to Supabase:', error);
      if (error) throw error
    } catch (error) {
      console.error('Error saving entries to Supabase:', error);
    } 
    this.checkedout=true;
    this.checkedoutChange.emit(this.checkedout);
  }
 
}