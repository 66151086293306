import { Injectable } from '@angular/core';
import { SupabaseClient } from '@supabase/supabase-js';
import { SupabaseService } from '../supabase/supabase.service';
import { Sponsor, SeriesInfo } from '../../models/sponsor/sponsor.model';

@Injectable({
  providedIn: 'root',
})
export class SponsorService {
  private supabase: SupabaseClient;

  constructor(private supabaseService: SupabaseService) {
    this.supabase = this.supabaseService.getClient();
  }

  // Fetch sponsor details
  getSponsor(sponsorID: string) {
    return this.supabase
      .from('sponsors')
      .select('name, website')
      .eq('id', sponsorID)
      .returns<Sponsor>()
      .single();
  }

  // Fetch series information
  getSeriesInfo(seriesID: string) {
    return this.supabase
      .from('meet_series')
      .select('name, description, sponsor_id')
      .eq('id', seriesID)
      .returns<SeriesInfo>()
      .single();
  }

  // Fetch series events
  getSeriesEvents(seriesID: string) {
    return this.supabase
      .from('events')
      .select('id')
      .eq('series_id', seriesID);
  }

  // Add a new song
  addSong(song: any) {
    return this.supabase.from('songs').upsert(song);
  }

  // Additional sponsor-related methods...
}