import { Component } from '@angular/core'
import { FormControl, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms'
import { SupabaseService } from '../../../services/supabase/supabase.service'
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatButtonModule } from '@angular/material/button';
import { MatTabsModule } from '@angular/material/tabs';
import { MatIconModule } from '@angular/material/icon';
import { AuthenticationService } from '../../../services/authentication/authentication.service';

@Component({
  selector: 'app-forgot-password',
  standalone: true,
  imports: [
    MatButtonModule,
    FormsModule,
    MatFormFieldModule,
    MatInputModule,
    ReactiveFormsModule,
    MatTabsModule,
    MatIconModule],
  templateUrl: './forgot-password.component.html',
  styleUrl: './forgot-password.component.css'
})
export class ForgotPasswordComponent {
  hide = true;
  loading = false
  password = new FormControl('', [Validators.required, Validators.minLength(8)])
  email = new FormControl('', [Validators.required, Validators.email]);
  session = this.authenticationServices.session

  constructor(private readonly supabase: SupabaseService,
    private readonly authenticationServices: AuthenticationService
  ) { }

  ngOnInit() {
    this.authenticationServices.authChanges((_, session) => (this.session = session))
  }

  async passwordChangeSubmit(): Promise<void> {
    try {
      this.loading = true
      const password = this.password.value as string
      const { error } = await this.authenticationServices.updatePassword(password)
      if (error) throw error
      alert('Password Changed!')
    } catch (error) {
      if (error instanceof Error) {
        alert(error.message)
      }
    } finally {
      this.password.reset
      this.loading = false
      window.location.href="../home"
    }
  }

  async passwordResetSubmit(): Promise<void> {
    try {
      this.loading = true
      const email = this.email.value as string
      const { error } = await this.authenticationServices.forgotPassword(email)
      if (error) throw error
      alert('Check your email for the reset link!')
    } catch (error) {
      if (error instanceof Error) {
        alert(error.message)
      }
    } finally {
      this.email.reset
      this.password.reset
      this.loading = false
    }
  }

}
