import { Component, OnInit } from '@angular/core';
import { HytekService } from '../../services/hytek.service';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { CommonModule } from '@angular/common';
import { FormsModule, NgModel } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { SupabaseService } from '../../../services/supabase/supabase.service';
import { stringify } from 'uuid';
import { RegistrationService } from '../../../services/registration/registration.service';

@Component({
  selector: 'app-hytek',
  standalone: true,
  imports: [
  MatFormFieldModule,
  MatSelectModule,
  FormsModule,
  CommonModule,
  MatButtonModule
],
  templateUrl: './hytek.component.html',
  styleUrl: './hytek.component.css'
})
export class HytekComponent implements OnInit {
  password: string = '';
  enteredPassword: string = '';
  accessGranted: boolean = false;
  
  selectedDate: Date | null = null;
  groupedEventsByDate: { [date: string]: any[] } = {};
  groupedAthletesByDate: { [date: string]: { [athleteId: string]: any } } = {};
  errorMessage: string | null = null;
  availableDates: Date[] = [];
  events: any;
  sortedAthletes: any[] = [];

  constructor(
    private hytekService: HytekService,
    private readonly supabase: SupabaseService,
    private readonly registrationService: RegistrationService
  ) {
    this.password = 'USATF2024'; // Set your desired password here
  }

  ngOnInit() {
    this.loadEventsFromSupabase();
  }

  async loadEventsFromSupabase() {
    const { data: events, error } = await this.registrationService.downloadEventRegistrations();
    if (error) {
      this.errorMessage = 'Failed to load events';
      return;
    }
    this.events = events;
    this.groupAthletesByDate(this.events);
    this.groupEventsByDate(this.events);
    this.initializeAvailableDates(this.events);
  }

  initializeAvailableDates(eventsData: any[]) {
    const uniqueDates = new Set<string>();
    eventsData.forEach(eventGroup => {
      eventGroup.events.forEach((event: any) => {
        const localDate = new Date(event.date).toLocaleDateString('en-US', {
          timeZone: 'America/New_York', // Adjust this to your specific timezone
          year: 'numeric',
          month: '2-digit',
          day: '2-digit'
        });
        uniqueDates.add(localDate);
      });
    });
    this.availableDates = Array.from(uniqueDates)
      .map(dateString => new Date(dateString))
      .sort((a, b) => a.getTime() - b.getTime()); // Sort dates in ascending order
  }

  groupEventsByDate(eventsData: any[]) {
    this.groupedEventsByDate = {};
    eventsData.forEach(eventGroup => {
      eventGroup.events.forEach((event: any) => {
        const date = new Date(event.date).toISOString().split('T')[0];
        if (!this.groupedEventsByDate[date]) {
          this.groupedEventsByDate[date] = [];
        }
        this.groupedEventsByDate[date].push(event);
      });
    });
  }

  generateDelimitedDataForDate(selectedDate: Date): string {
    const dateStr = selectedDate.toISOString().split('T')[0];
    const filteredEvents = this.groupedEventsByDate[dateStr] || [];

    return this.hytekService.convertJsonToDelimitedByDate(filteredEvents, dateStr);
  }

    groupAthletesByDate(eventsData: any[]) {
    this.groupedAthletesByDate = {};
    eventsData.forEach(eventGroup => {
      eventGroup.events.forEach((event: any) => {
        const date = new Date(event.date).toISOString().split('T')[0];
        if (!this.groupedAthletesByDate[date]) {
          this.groupedAthletesByDate[date] = {};
        }
        event.athletes.forEach((athlete: any) => {
          if (!this.groupedAthletesByDate[date][athlete.id]) {
            this.groupedAthletesByDate[date][athlete.id] = {
              name: athlete.name,
              team: athlete.team,
              dob: new Date(athlete.dob).toISOString().split('T')[0],
              gender: athlete.gender,
              events: [],
              paid: eventGroup.paid, // Get the payment status from the event
              email: athlete.email,
              phone: eventGroup.phone,
              payerName: eventGroup.name,
              payerEmail: eventGroup.email
            };
          }
          
          this.groupedAthletesByDate[date][athlete.id].events.push({
            name: event.name,
            seedMark: athlete.seedMark
           }); // Get the event name
        });
      });
    });
  }

  sortAthletes(date: string) {
    const athletes = Object.values(this.groupedAthletesByDate[date] || {});
    athletes.sort((a: any, b: any) => {
      // Condition to check birth year
      const aIsBorn2024 = new Date(a.dob).getFullYear() === 2024;
      const bIsBorn2024 = new Date(b.dob).getFullYear() === 2024;
  
      if (!a.paid && !b.paid) {
        return a.name.localeCompare(b.name);
      }
      if (!a.paid) return -1;
      if (!b.paid) return 1;
      if (aIsBorn2024 && !bIsBorn2024) return -1;
      if (!aIsBorn2024 && bIsBorn2024) return 1;
  
      return a.name.localeCompare(b.name);
    });
    this.sortedAthletes = athletes;
  }

  downloadDelimitedData() {
    if (this.selectedDate) {
      const data = this.generateDelimitedDataForDate(this.selectedDate);
      const blob = new Blob([data], { type: 'text/plain' });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = `event-data-${this.selectedDate?.toISOString().split('T')[0]}.txt`;
      a.click();
      window.URL.revokeObjectURL(url);
    } else {
      this.errorMessage = 'Please select a date first.';
    }
  }
  onDateChange() {
    if (this.selectedDate) {
      const dateStr = this.selectedDate.toISOString().split('T')[0];
      this.sortAthletes(dateStr);
    }
  }
  checkPassword() {
    if (this.enteredPassword === this.password) {
      this.accessGranted = true;
      this.errorMessage = null;
    } else {
      this.errorMessage = 'Invalid password';
    }
  }
}