<!---<div>{{tickets | json}}</div>-->

<div *ngIf="errorMessage" class="error-message">
    {{ errorMessage }}
  </div>
  
  <div *ngIf="tickets">
    <table>
      <thead>
        <tr>
          <th>Name</th>
          <th>Friday</th>
          <th>Saturday</th>
          <th>Sunday</th>
          <th>Multi-Day</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let ticket of tickets">
          <td>{{ ticket.name }}</td>
          <td>{{ ticket.friday.join(', ') }}</td>
          <td>{{ ticket.saturday.join(', ') }}</td>
          <td>{{ ticket.sunday.join(', ') }}</td>
          <td>{{ ticket.multiDay.join(', ') }}</td>
        </tr>
      </tbody>
    </table>
  </div>
