<html lang="en">

<body>
  <main>
    <section class="hero">
      <h2>Tell your Story</h2>
      <p>Make your trading card today!</p>
      <a mat-raised-button [ngClass]="profile ? 'active' : 'notActive'" *ngIf="session; else login" href="profile">Edit
        Profile</a>
      <ng-template #login>
        <a mat-raised-button [ngClass]="profile ? 'active' : 'notActive'" href="profile/signup">Make a Profile</a>
      </ng-template>
      <div class="event-button">
        <a class="register" mat-raised-button href="events">Register For Upcoming Events</a>
      </div>
    </section>


    <div class="my-grid">
      <section class="features">



        <mat-card class="card">
          <div class="image-size">
            <img mat-card-image ngSrc="../../../assets/images/pennRelays.jpg" alt="Photo of a Commentator" priority width="240" height="300">
          </div>
          <mat-card-content>
            <h3>Help the Commentators</h3>
            <p>Let the commentators know who you are both on and off the track.</p>
          </mat-card-content>
        </mat-card>

        <mat-card class="card">

          <div class="image-size">
            <img mat-card-image ngSrc="../../../../assets/images/profilePhoto.jpg" alt="Default Avatar"  width="300" height="300">
          </div>
          <mat-card-content>
            <h3>Trading Cards</h3>
            <p>Create your trading card to showcase your achievements.</p>
          </mat-card-content>
        </mat-card>

        <mat-card class="card">
          <div class="image-size">
            <img mat-card-image ngSrc="../../../assets/images/events.jpg" alt="Photo of The Penn Relays"  width="240" height="300">
          </div>
          <mat-card-content>
            <h3>Stay Updated on Events</h3>
            <p>Find information about upcoming events and connect with fellow Athletes.</p>
          </mat-card-content>
        </mat-card>


      </section>
    </div>
  </main>
</body>

</html>