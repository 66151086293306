import { Component, Inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-event-info',
  standalone: true,
  imports: [MatButtonModule],
  templateUrl: './event-info.component.html',
  styleUrl: './event-info.component.css'
})
export class EventInfoComponent {
  constructor(
    public dialogRef: MatDialogRef<EventInfoComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { location: { name: string, link: string }, timer: { name: string, link: string } }
  ) { }

  closeDialog(): void {
    this.dialogRef.close();
  }
}
