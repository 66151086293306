import { Injectable } from '@angular/core';
import { SupabaseClient, AuthChangeEvent, Session, User, AuthSession } from '@supabase/supabase-js';
import { SupabaseService } from '../supabase/supabase.service';


@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  private supabase: SupabaseClient;
  _session: AuthSession | null = null

  constructor(private supabaseService: SupabaseService) {
    this.supabase = this.supabaseService.getClient();
  }

  // Listen to authentication state changes
  authChanges(callback: (event: AuthChangeEvent, session: Session | null) => void) {
    return this.supabase.auth.onAuthStateChange(callback);
  }

  // Sign up a new user
  signUp(email: string, password: string) {
    return this.supabase.auth.signUp({
      email,
      password,
      options: { emailRedirectTo: 'https://mosaic-sports.com/profile' }, // Update as needed
    });
  }

  // Sign in with email and password
  signInPassword(email: string, password: string) {
    return this.supabase.auth.signInWithPassword({ email, password })
  }

  // Sign in using magic link (OTP)
  signInWithOtp(email: string) {
    return this.supabase.auth.signInWithOtp({ email });
  }

  // Reset password
  forgotPassword(email: string) {
    return this.supabase.auth.resetPasswordForEmail(email, {
      redirectTo: 'https://mosaic-sports.com/update-password', // Update as needed
    });
  }

  // Update password
  updatePassword(newPassword: string) {
    return this.supabase.auth.updateUser({ password: newPassword });
  }

  // Sign out
  signOut() {
    return this.supabase.auth.signOut();
  }

  // Get current session
  get session() {
    this.supabase.auth.getSession().then(({ data }) => {
      this._session = data.session
    })
    return this._session
  }

  // Get current user
  async getCurrentUser(): Promise<User | null> {
    const { data } = await this.supabase.auth.getUser();
    return data.user;
  }

  // Retrieve access token on-demand
    async getAccessToken(): Promise<string | null> {
      const { data } = await this.supabase.auth.getSession();
      return data.session?.access_token || null;
    }
}