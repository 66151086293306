import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CardDisplay } from '../../../models/athlete/athlete.model';
import {  MatFormFieldModule, MatLabel } from '@angular/material/form-field';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { CommonModule } from '@angular/common';
import { MatInputModule } from '@angular/material/input';
import { MatTabsModule } from '@angular/material/tabs';
import { SongSearchComponent } from '../song-search/song-search.component';


@Component({
  selector: 'app-edit-card-data',
  standalone: true,
  imports: [
  MatFormFieldModule,
  MatInputModule,
  MatLabel,
  FormsModule,
  MatButtonModule,
  CommonModule,
  MatTabsModule,
SongSearchComponent],
  templateUrl: './edit-card-data.component.html',
  styleUrl: './edit-card-data.component.css'
})
export class EditCardDataComponent {
  constructor(
    public dialogRef: MatDialogRef<EditCardDataComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { cardDisplay: CardDisplay, section: string }
  ) { }

  onCancel(): void {
    this.dialogRef.close();
  }

  onSave(): void {
    this.dialogRef.close(this.data.cardDisplay);
  }
  
}