import { Component, OnInit, OnDestroy } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { FooterComponent } from './shared/components/footer/footer.component';
import { SupabaseService } from './services/supabase/supabase.service';
import { NavigationComponent } from './shared/components/navigation/navigation.component';
import { NgcCookieConsentService, NgcInitializingEvent, NgcInitializationErrorEvent, NgcStatusChangeEvent, NgcNoCookieLawEvent } from 'ngx-cookieconsent';
import { Subscription } from 'rxjs';
import { GoogleAnalyticsService } from 'ngx-google-analytics';import { bootstrapApplication } from '@angular/platform-browser';
import { AuthenticationService } from './services/authentication/authentication.service';
import { environment } from '../environments/environment';
//import { NgxStripeModule, StripeService, injectStripe, provideNgxStripe } from 'ngx-stripe';


@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet, FooterComponent, NavigationComponent],
  templateUrl: './app.component.html',
  styleUrl: './app.component.css'
})


export class AppComponent implements OnInit, OnDestroy {
  title = 'mosaic-sports-standalone';
  session = this.authenticationService.session

  private popupOpenSubscription!: Subscription;
  private popupCloseSubscription!: Subscription;
  private initializingSubscription!: Subscription;
  private initializedSubscription!: Subscription;
  private initializationErrorSubscription!: Subscription;
  private statusChangeSubscription!: Subscription;
  private revokeChoiceSubscription!: Subscription;
  private noCookieLawSubscription!: Subscription;

  constructor(private readonly authenticationService: AuthenticationService, 
    private ccService: NgcCookieConsentService, 
    private gaService: GoogleAnalyticsService,) { }

  ngOnInit() {
    if (environment.passwordProtection) {
      this.promptForPassword();
    }
    this.authenticationService.authChanges((_, session) => (this.session = session))
    // subscribe to cookieconsent observables to react to main events
    this.popupOpenSubscription = this.ccService.popupOpen$.subscribe(
      () => {
        // you can use this.ccService.getConfig() to do stuff...
        console.log('popupOpen');
      });

    this.popupCloseSubscription = this.ccService.popupClose$.subscribe(
      () => {
        // you can use this.ccService.getConfig() to do stuff...
        console.log('popuClose');
      });

    this.initializingSubscription = this.ccService.initializing$.subscribe(
      (event: NgcInitializingEvent) => {
        // the cookieconsent is initilializing... Not yet safe to call methods like `NgcCookieConsentService.hasAnswered()`
        console.log(`initializing: ${JSON.stringify(event)}`);
      });

    this.initializedSubscription = this.ccService.initialized$.subscribe(
      () => {
        // the cookieconsent has been successfully initialized.
        // It's now safe to use methods on NgcCookieConsentService that require it, like `hasAnswered()` for eg...
        console.log(`initialized: ${JSON.stringify(event)}`);
      });

    this.initializationErrorSubscription = this.ccService.initializationError$.subscribe(
      (event: NgcInitializationErrorEvent) => {
        // the cookieconsent has failed to initialize... 
        console.log(`initializationError: ${JSON.stringify(event.error?.message)}`);
      });

    this.statusChangeSubscription = this.ccService.statusChange$.subscribe(
      (event: NgcStatusChangeEvent) => {
        // you can use this.ccService.getConfig() to do stuff...
        console.log(`statusChange: ${JSON.stringify(event)}`);
        this.handleConsentChange(event.status);
      });

    this.revokeChoiceSubscription = this.ccService.revokeChoice$.subscribe(
      () => {
        // you can use this.ccService.getConfig() to do stuff...
        console.log(`revokeChoice`);
      });

    this.noCookieLawSubscription = this.ccService.noCookieLaw$.subscribe(
      (event: NgcNoCookieLawEvent) => {
        // you can use this.ccService.getConfig() to do stuff...
        console.log(`noCookieLaw: ${JSON.stringify(event)}`);
      });

  }

  ngOnDestroy() {
    // unsubscribe to cookieconsent observables to prevent memory leaks
    this.popupOpenSubscription.unsubscribe();
    this.popupCloseSubscription.unsubscribe();
    this.initializingSubscription.unsubscribe();
    this.initializedSubscription.unsubscribe();
    this.initializationErrorSubscription.unsubscribe();
    this.statusChangeSubscription.unsubscribe();
    this.revokeChoiceSubscription.unsubscribe();
    this.noCookieLawSubscription.unsubscribe();
  }
  private handleConsentChange(status: string): void {
    if (status === 'allow') {
      this.gaService.gtag('consent', 'update', {
        'analytics_storage': 'granted'
      });
    } else {
      this.gaService.gtag('consent', 'update', {
        'analytics_storage': 'denied'
      });
    }
  }

  promptForPassword(): void {
    const password = prompt('Please enter the beta access password:');
    if (password !== environment.loginPassword) {
      alert('Incorrect password. You will be redirected.');
      window.location.href = 'https://mosaic-sports.com';
    }
  }

}
/*bootstrapApplication(AppComponent, {
  providers: [provideNgxStripe('pk_test_51O7lEzCreZRsfGkIosnIBx6P0Ki8CsJgk3lrWlHbNPfSbCZi436cskPajchwU6EPAX96bE1bME1U6kRUEQcg8ZJU00sADiEw1v')]
});*/