<body>
  <h1 class="header">mosaic-sports</h1>
  <mat-tab-group class="tabs" [(selectedIndex)]="selectedTab" dynamicHeight mat-stretch-tabs="false" mat-align-tabs="center">

    <!-- <mat-tab class="tab">
      <ng-template mat-tab-label>
        <a class="label">Magic Link</a>
      </ng-template>

      <div class="row flex-center flex">
        <div class="col form-widget" aria-live="polite">

          <p class="description">Sign in via magic link with your email below</p>
          <form class="sign-in-form" (ngSubmit)="magicLinkSubmit()" class="form-widget">
            <mat-form-field class="width">
              <mat-label>Email</mat-label>
              <input type="email" matInput [formControl]="email" placeholder="Ex. pat@example.com">
              @if (email.hasError('email') && !email.hasError('required')) {
              <mat-error>Please enter a valid email address</mat-error>
              }
              @if (email.hasError('required')) {
              <mat-error>Email is <strong>required</strong></mat-error>
              }
            </mat-form-field>
            <button mat-raised-button type="submit"
              [disabled]="loading || email.hasError('email') || email.hasError('required')">
              {{ loading ? 'Loading' : 'Send magic link' }}
            </button>
          </form>
        </div>
      </div>
    </mat-tab>-->




   


    <mat-tab class="tab">
      <ng-template mat-tab-label>
        <a class="label">New User</a>
      </ng-template>

      <div class="row flex-center flex">
        <div class="col form-widget" aria-live="polite">

          <p class="description">Sign up via password with your email below</p>
          <form class="sign-in-form" (ngSubmit)="signUpSubmit()" class="form-widget">
            <mat-form-field class="width">
              <mat-label>Email</mat-label>
              <input type="email" matInput [formControl]="email" placeholder="Ex. pat@example.com">
              @if (email.hasError('email') && !email.hasError('required')) {
              <mat-error>Please enter a valid email address</mat-error>
              }
              @if (email.hasError('required')) {
              <mat-error>Email is <strong>required</strong></mat-error>
              }
            </mat-form-field>
            <mat-form-field class="width">
              <mat-label>Password</mat-label>
              <input matInput [formControl]="newPassword" [type]="hide ? 'password' : 'text'">
              <button type="button" mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'"
                [attr.aria-pressed]="hide">
                <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
              </button>
              @if (newPassword.hasError('minlength') && !newPassword.hasError('required')) {
              <mat-error>Password must be a minimum of 8 characters</mat-error>
              }
              @if (newPassword.hasError('required')) {
              <mat-error>Password is <strong>required</strong></mat-error>
              }
            </mat-form-field>
            <button mat-raised-button type="submit"
              [disabled]="loading || email.hasError('email') || email.hasError('required') || newPassword.hasError('minlength') || newPassword.hasError('required')">
              {{ loading ? 'Loading' : 'Sign Up' }}
            </button>
          </form>
        </div>
      </div>
    </mat-tab>
    <mat-tab class="tab">
      <ng-template mat-tab-label>
        <a class="label">Returning User</a>
      </ng-template>

      <div class="row flex-center flex">
        <div class="col form-widget" aria-live="polite">

          <p class="description">Sign in via password with your email below</p>
          <form class="sign-in-form" (ngSubmit)="passwordSubmit()" class="form-widget">
            <mat-form-field class="width">
              <mat-label>Email</mat-label>
              <input type="email" matInput [formControl]="email" placeholder="Ex. pat@example.com">
              @if (email.hasError('email') && !email.hasError('required')) {
              <mat-error>Please enter a valid email address</mat-error>
              }
              @if (email.hasError('required')) {
              <mat-error>Email is <strong>required</strong></mat-error>
              }
            </mat-form-field>
            <mat-form-field class="width">
              <mat-label>Password</mat-label>
              <input matInput [formControl]="password" [type]="hide ? 'password' : 'text'">
              <button type="button" mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'"
                [attr.aria-pressed]="hide">
               <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
              </button>
              @if (password.hasError('required')) {
              <mat-error>Password is <strong>required</strong></mat-error>
              }

            </mat-form-field>
            <a mat-button class="forgot" color="warn" href="/update-password">Forgot Password</a>

            <button mat-raised-button type="submit"
              [disabled]="loading || email.hasError('email') || email.hasError('required') || password.hasError('minlength') || password.hasError('required')">
              {{ loading ? 'Loading' : 'Sign In' }}
            </button>
          </form>
        </div>
      </div>
    </mat-tab>

  </mat-tab-group>

</body>


<!--<div class="row flex-center flex">
  <div class="col form-widget" aria-live="polite">
    <h1 class="header">mosaic-sports</h1>
    <p class="description">Sign in via magic link with your email below</p>
    <form class="sign-in-form" (ngSubmit)="magicLinkSubmit()" class="form-widget">
      <mat-form-field class="width">
        <mat-label>Email</mat-label>
        <input type="email" matInput [formControl]="email" placeholder="Ex. pat@example.com">
        @if (email.hasError('email') && !email.hasError('required')) {
        <mat-error>Please enter a valid email address</mat-error>
        }
        @if (email.hasError('required')) {
        <mat-error>Email is <strong>required</strong></mat-error>
        }
      </mat-form-field>
      <button mat-raised-button type="submit"
        [disabled]="loading || email.hasError('email') || email.hasError('required')">
        {{ loading ? 'Loading' : 'Send magic link' }}
      </button>
    </form>
  </div>
</div>-->