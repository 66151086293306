import { Injectable } from '@angular/core';
import { SupabaseClient, User } from '@supabase/supabase-js';
import { SupabaseService } from '../supabase/supabase.service';
import { Profile } from '../../models/profile/profile.model';

@Injectable({
  providedIn: 'root',
})
export class ProfileService {
  private supabase: SupabaseClient;

  constructor(private supabaseService: SupabaseService) {
    this.supabase = this.supabaseService.getClient();
  }

  // Fetch user profile
  getProfile(user: User) {
    return this.supabase
      .from('user_data')
      .select('full_name')
      .eq('id', user.id)
      .returns<Profile>()
      .single();
  }

  // Update user profile
  updateProfile(profile: Profile) {
    const updatedProfile = {
      ...profile,
      updated_at: new Date(),
    };

    return this.supabase.from('user_data').upsert(updatedProfile);
  }
}