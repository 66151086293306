
import { Component, ElementRef, EventEmitter, Input, Output, Renderer2 } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatListModule } from '@angular/material/list';
import { YouTubeService } from '../../services/youtube.service';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { SpotifyService } from '../../services/spotify.service';
import { MatCardModule } from '@angular/material/card';

@Component({
  selector: 'app-song-list',
  standalone: true,
  imports: [MatListModule,
    MatButtonModule,
    MatCardModule
  ],
  templateUrl: './song-list.component.html',
  styleUrl: './song-list.component.css'
})
export class SongListComponent {
  @Input() songs: any[] = [];
  @Output() addSong = new EventEmitter<any>();

  selectedSong: any;
  playerHtml: SafeHtml = '';
  
  constructor(private spotifyService: SpotifyService, private sanitizer: DomSanitizer, private renderer: Renderer2, private el: ElementRef) { }

  onSelectSong(song: any): void {
    this.selectedSong = song;
   /* this.youTubeService.getVideoDetails(song.id.videoId).subscribe({
      next: (response) => {
        console.log(response);
        if (response.items && response.items.length > 0) {
          this.selectedSong = response.items[0];
          this.playerHtml = this.sanitizer.bypassSecurityTrustHtml(this.selectedSong.player.embedHtml);
   // Use a timeout to allow the DOM to update
   setTimeout(() => {
    const iframe = this.el.nativeElement.querySelector('.now-playing iframe');
    if (iframe) {
      this.renderer.setAttribute(iframe, 'width', '200');
      this.renderer.setAttribute(iframe, 'height', '150');
    }
  }, 0);
}
},
error: (error) => {
console.error('Error fetching video details:', error);
}
    });*/
  }

  getSpotifyLink(song: any): string {
    return `https://open.spotify.com/track/${song.id}`;
  }
}