import { Injectable } from '@angular/core';
import { SupabaseClient } from '@supabase/supabase-js';
import { SupabaseService } from '../supabase/supabase.service';
import { Event, SubEvent, EventInfo, SubEventInfo } from '../../models/event/event.model';

@Injectable({
  providedIn: 'root',
})
export class EventService {
  private supabase: SupabaseClient;

  constructor(private supabaseService: SupabaseService) {
    this.supabase = this.supabaseService.getClient();
  }

  // Fetch a single event
  getEvent(eventId: string) {
    return this.supabase
      .from('events')
      .select('event_name, start_date, end_date, sport, venue_id')
      .eq('id', eventId)
      .returns<Event>()
      .single();
  }

  // Fetch multiple sub-events
  getSubEvents(eventIds: string[]) {
    return this.supabase
      .from('sub_events')
      .select('id, event_id, scheduled_time, event_name, event_number, sponsor_id, location, field_event, final, relay')
      .in('event_id', eventIds)
      .order('event_number', { ascending: true });
      
  }

  // Create a new event
  createEvent(eventData: Partial<Event>) {
    const newEvent = {
      ...eventData,
      created_at: new Date(),
      updated_at: new Date(),
    };

    return this.supabase.from('events').insert(newEvent).select('*').single();
  }

  // Update an existing event
  updateEvent(eventId: string, eventData: Partial<Event>) {
    const updatedEvent = {
      ...eventData,
      updated_at: new Date(),
    };

    return this.supabase.from('events').update(updatedEvent).eq('id', eventId).select('*').single();
  }

  // Delete an event
  deleteEvent(eventId: string) {
    return this.supabase.from('events').delete().eq('id', eventId);
  }

  // Fetch event info for a list of event IDs
  getEventInfo(eventIDs: string[]) {
    return this.supabase
      .from('events')
      .select('id, event_name, start_date, end_date, venue_id, sport, sponsors, timers_id, live_results, final_results, open_price')
      .in('id', eventIDs);
  }

  // Additional event-related methods...
}