import { Component } from '@angular/core';
import { YouTubeService } from '../../services/youtube.service';
import { MatFormFieldModule } from '@angular/material/form-field';
import { SongListComponent } from '../song-list/song-list.component';
import { CommonModule } from '@angular/common';
import { FormsModule, NgModel } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { SpotifyService } from '../../services/spotify.service';
import { SupabaseService } from '../../../services/supabase/supabase.service';
import { SongService } from '../../../services/song/song.service';

@Component({
  selector: 'app-song-search',
  standalone: true,
  imports: [MatFormFieldModule,
    SongListComponent,
    MatInputModule,
    FormsModule
  ],
  templateUrl: './song-search.component.html',
  styleUrl: './song-search.component.css'
})
export class SongSearchComponent {

  searchQuery: string = '';
  songs: any[] = [];
  loading = false;

  constructor(private readonly songService: SongService, 
    private spotifyService: SpotifyService) { }

  onSearch(): void {
    this.spotifyService.searchSongs(this.searchQuery).subscribe(results => {
      this.songs = results.tracks.items; // Adjust according to API response
    });
  }

  async onAddSong(song: any): Promise<void> {
    try {
      this.loading = true
      const { error } = await this.songService.addSong(song)

      if (error) {
        throw error
      }
    } catch (error) {
      if (error instanceof Error) {
        alert(error.message)
      }
    } finally {
      this.loading = false
    }
  }

}