import { Component } from '@angular/core';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { AuthenticationService } from '../../services/authentication/authentication.service';
import { Meta, Title } from '@angular/platform-browser';



@Component({
  selector: 'app-home',
  standalone: true,
  imports: [
    CommonModule, 
    MatButtonModule, 
    MatCardModule, 
    NgOptimizedImage
],
  templateUrl: './home.component.html',
  styleUrl: './home.component.css'
})
export class HomeComponent {
  profile = false;
  session = this.authenticationServcice.session
  
  constructor(private readonly authenticationServcice: AuthenticationService,private meta: Meta, private titleService: Title) {
    this.setMetaTags();
  }
  
  ngOnInit() {
    this.authenticationServcice.authChanges((_, session) => (this.session = session))
  }


  setMetaTags() {
    this.titleService.setTitle('mosaic-sports | Share Your Story!');
    this.meta.addTags([
      { name: 'description', content: 'Create trading cards, register for meets, and get paid for working events on mosaic-sports. A comprehensive platform for athletes, coaches, and event organizers.' },
      { name: 'keywords', content: 'mosaic-sports, track and field, trading cards, races, NIL, meet management, event management, Olympic sports' },
      { name: 'robots', content: 'index, follow' },
      { name: 'author', content: 'mosaic-sports' },
      { name: 'viewport', content: 'width=device-width, initial-scale=1' },
      { name: 'og:title', content: 'mosaic-sports | Share Your Story!' },
      { name: 'og:description', content: 'Join mosaic-sports to create trading cards, register for meets, and streamline athlete and officail payments. A platform tailored for Olympic sports and track and field.' },
      { name: 'og:image', content: 'https://mosaic-sports.com/assets/images/profilePhoto.jpg' },
      { name: 'og:url', content: 'https://mosaic-sports.com/home' }
    ]);
  }
}
