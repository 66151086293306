import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UserCardComponent } from '../../shared/components/user-card/user-card.component';
import { MatButtonModule } from '@angular/material/button';
import { MatTabsModule } from '@angular/material/tabs';
import { AuthenticationService } from '../../services/authentication/authentication.service';
import { Athlete } from '../../models/athlete/athlete.model';
import { AthleteService } from '../../services/athlete/athlete.service';
import { URLHandlerService } from '../../services/urlhandler/urlhandler.service';

@Component({
  selector: 'app-profile-viewer',
  standalone: true,
  imports: [UserCardComponent, MatButtonModule, MatTabsModule],
  templateUrl: './profile-viewer.component.html',
  styleUrl: './profile-viewer.component.css'
})
export class ProfileViewerComponent implements OnInit {
  session = this.authenticationService.session
  loading = false;
  userID = "";
  testId1 = "one"
  testId2 = "0daed35c-e08e-4463-9134-111315626bb1";
  userIDBase64 = "hi"
  userIDHex = "2"
  url = ""
  athlete!: Athlete

  constructor(private readonly authenticationService: AuthenticationService,
    private readonly athleteService: AthleteService, 
    private route: ActivatedRoute, 
    private readonly URLHandler: URLHandlerService) { }


  async ngOnInit(): Promise<void> {
    this.route.params.subscribe(params => {
      this.userID = params['id']; // Access the 'id' parameter from the URL
    });
    await this.URLHandler.base64ToHex(this.userID).then(res => {
      this.userID = res;
    })
    await this.getAthleteData()
   


    /* Tests
    await this.hexToBase64(this.userID).then(res => {
      this.userIDBase64 = res;
    })

    this.testId1 = this.userIDBase64.replace(/=/g, "")

    await this.base64ToHex(this.userIDBase64).then(res => {
      this.userIDHex = res;
    })

  await this.base64ToHex(this.testId1).then(res => {
      this.testId2 = res;
    })
    const { name, nickname, strava, world_athletics, milesplit, tfrrs, instagram } = this.athlete

    /*this.userIDBase64=  Buffer.from("0daed35c-e08e-4463-9134-111315626bb1").toString('base64');
    this.userIDBinary= Buffer.from(this.userIDBase64, 'base64').toString('binary');*/


/*
    this.userIDBase64 = this.testId1.toString()
    this.userIDBase64 = window.btoa(this.userIDBase64)
    this.userIDBinary = window.atob(this.userIDBase64)
    */

  }

  async getAthleteData() {
    try {
      this.loading = true
      const { data: athlete, error, status } = await this.athleteService.getAthlete(this.userID)

      if (error && status !== 406) {
        throw error
      }

      if (athlete) {
        this.athlete = athlete
      }
    } catch (error) {
      if (error instanceof Error) {
        alert(error.message)
      }
    } finally {
      this.loading = false
    }
  }





}


//if the card does not exist it breaks our code!
//fixed by adding @if to usercard html should probaby make a blank card