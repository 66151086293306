@if(selectedSong){
<div class="now-playing">
  <h2>Now Playing: {{ selectedSong.name }}</h2>
  <div>By {{ selectedSong.artists[0].name }}</div>
</div>

<mat-card>
  <mat-card-header>
    <mat-card-title>
      {{selectedSong.name}}
    </mat-card-title>
    <mat-card-subtitle>
      by {{ selectedSong.artists[0].name }}
    </mat-card-subtitle>
  </mat-card-header>
  <img class="album-cover" [src]="selectedSong.album.images[0]?.url" alt="{{ selectedSong.name }} album cover">
  <mat-card-actions>
    <button mat-raised-button color="primary" (click)="addSong.emit(selectedSong)">Add to My Songs</button>
  </mat-card-actions>
</mat-card>



}
@for(song of songs; track song){
<mat-card>
  <mat-card-header>
    <mat-card-title>
      {{song.name}}
    </mat-card-title>
    <mat-card-subtitle>
      by {{ song.artists[0].name }}
    </mat-card-subtitle>
  </mat-card-header>
  <img class="album-cover" [src]="song.album.images[0]?.url" alt="{{ song.name }} album cover">
  <mat-card-actions>
    <button mat-raised-button color="primary" (click)="addSong.emit(song)">Add to My Songs</button>
    <a mat-button color="accent" [href]="getSpotifyLink(song)" target="_blank">Play</a>
  </mat-card-actions>
</mat-card>

}