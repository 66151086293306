import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatNativeDateModule } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { AddEventComponent } from '../add-event/add-event.component';
import { Router } from '@angular/router';
import { MeetDTO, MeetService } from '../../services/meet.service';

@Component({
  selector: 'app-create-meet',
  standalone: true,
  imports: [
    CommonModule,
    MatSelectModule,
    MatButtonModule,
    MatInputModule,
    MatNativeDateModule,
    MatCardModule,
    MatIconModule,
    MatFormFieldModule,
    MatDatepickerModule,
    MatCheckboxModule,
    FormsModule,
    ReactiveFormsModule,
    AddEventComponent 
  ],
  templateUrl: './create-meet.component.html',
  styleUrl: './create-meet.component.css'
})
export class CreateMeetComponent  implements OnInit {

  meetForm: FormGroup;
  isLoading = false;
  isFlatFee: boolean = false;

  constructor(
    private fb: FormBuilder,
    private meetService: MeetService,
    //private authService: AuthService,
    private snackBar: MatSnackBar,
    private router: Router
  ) {
    this.meetForm = this.fb.group({
      title: ['', Validators.required],
      description: [''],
      location: ['', Validators.required],
      date: ['', Validators.required],
      isFlatFee: [false],
      flatFeeAmount: [{ value: 0, disabled: true }, [Validators.min(0)]]
    });
  }

  ngOnInit(): void {
    // Listen to changes on the 'isFlatFee' checkbox to enable/disable the 'flatFeeAmount' field
    this.meetForm.get('isFlatFee')?.valueChanges.subscribe(value => {
      this.isFlatFee = value;
      if (value) {
        this.meetForm.get('flatFeeAmount')?.enable();
      } else {
        this.meetForm.get('flatFeeAmount')?.disable();
        this.meetForm.get('flatFeeAmount')?.setValue(0);
      }
    });
  }

  onSubmit() {
    if (this.meetForm.invalid) {
      this.snackBar.open('Please fill in all required fields.', 'Close', { duration: 3000 });
      return;
    }

    this.isLoading = true;

   /* const currentUser = this.authService.getCurrentUser(); // Implement this method to get the logged-in user
    if (!currentUser) {
      this.snackBar.open('User not authenticated.', 'Close', { duration: 3000 });
      this.isLoading = false;
      return;
    }*/

    const meetData: MeetDTO = {
      title: this.meetForm.value.title,
      description: this.meetForm.value.description,
      location: this.meetForm.value.location,
      date: this.meetForm.value.date,
      isFlatFee: this.meetForm.value.isFlatFee,
      flatFeeAmount: this.meetForm.value.isFlatFee ? this.meetForm.value.flatFeeAmount : undefined
    };

    this.meetService.createMeet(meetData).subscribe({
      next: (meet) => {
        this.isLoading = false;
        this.snackBar.open('Meet created successfully!', 'Close', { duration: 3000 });
        // Navigate to MeetDetailComponent with the newly created meet_id
        this.router.navigate(['/meets', meet.meet_id]);
      },
      error: (error) => {
        this.isLoading = false;
        this.snackBar.open(`Error: ${error.error.message || 'Failed to create meet.'}`, 'Close', { duration: 5000 });
      }
    });
  }

}