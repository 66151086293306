import { Component } from '@angular/core';
import { NavigationComponent } from '../../shared/components/navigation/navigation.component';
import { UserCardComponent } from '../../shared/components/user-card/user-card.component';

@Component({
  selector: 'app-contact',
  standalone: true,
  imports: [ ],
  templateUrl: './contact.component.html',
  styleUrl: './contact.component.css'
})
export class ContactComponent {

}
