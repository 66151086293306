<body>

  <mat-tab-group class="tabs" dynamicHeight mat-stretch-tabs="false" mat-align-tabs="center"  [selectedIndex]="selectedIndex"
  (swipeleft)="onSwipeLeft()"
  (swiperight)="onSwipeRight()">
    <mat-tab class="tab">
      <ng-template mat-tab-label>
          <a class="label"> Card</a> 
      </ng-template>
      <div class="profile">
        <app-user-card class="card" [sessionSent]=true [authSession]="session" [userID]="userID"></app-user-card>
      </div>
      </mat-tab>
    
    <mat-tab class="tab">
      <ng-template mat-tab-label>
         <a class="label"> Links</a>
      </ng-template>
      <div class="profile">
        <form [formGroup]="updateSocialForm" (ngSubmit)="updateSocial()" class="form-widget">
          <div class = "input">
            <label for="email">Email</label>
            <input id="email" type="text" [value]="session.user.email" disabled />
          </div>
          <div>
            <label for="strava">Strava</label>
            <input formControlName="strava" id="strava" type="url" placeholder="https://www.strava.com/athletes/id" />
          </div>
          <div>
            <label for="world_athletics">World Athletics</label>
            <input formControlName="world_athletics" id="world_athletics" type="url" placeholder="https://worldathletics.org/athletes/united-states/id" />
          </div>
          <div>
            <label for="milesplit">MileSplit</label>
            <input formControlName="milesplit" id="milesplit" type="url" placeholder="https://milesplit.com/athletes/id" />
          </div>
          <div>
            <label for="tfrrs">TFRRS</label>
            <input formControlName="tfrrs" id="tfrrs" type="url" placeholder="https://www.tfrrs.org/athletes/id" />
          </div>
          <div>
            <label for="instagram">Instagram</label>
            <input formControlName="instagram" id="instagram" type="url" placeholder="https://www.instagram.com/id"/>
          </div>
          <div class="buttons">
            <button mat-raised-button  type="submit" class="button primary block" [disabled]="loading" color="primary">
              {{ loading ? 'Loading ...' : 'Update' }}
            </button>
          </div>
        </form>
      </div>
    </mat-tab>
          <mat-tab class="tab">
              <ng-template mat-tab-label>
                  <a class="label"> Account</a> 
              </ng-template>
              <div class="profile">
                <form [formGroup]="updateProfileForm" (ngSubmit)="updateProfile()" class="form-widget">
                  <div>
                    <label for="email">Email</label>
                    <input id="email" type="text" [value]="session.user.email" disabled />
                  </div>
                  <div>
                    <label for="full_name">Name</label>
                    <input formControlName="full_name" id="full_name" type="text" />
                  </div>
          
                  <div class="buttons">
                    <button mat-raised-button  type="submit" class="button primary block" [disabled]="loading" color="primary">
                      {{ loading ? 'Loading ...' : 'Update' }}
                    </button>
                  </div>
          
                </form>
              </div>
          </mat-tab>
    
        </mat-tab-group>

        
        <div class="sign-out">
          <button mat-raised-button class="button block signOut" (click)="signOut()" color="warn">Sign Out</button>
        </div>
      </body>
    
    
    <!--
    <mat-tab class="tab">
      <ng-template mat-tab-label>
          <a class="label"> Card Data</a> 
      </ng-template>
      <div class="profile">
        <form [formGroup]="updateCardDataForm" (ngSubmit)="updateCardData()" class="form-widget">
          <div>
            <label for="email">Email</label>
            <input id="email" type="text" [value]="session.user.email" disabled />
          </div>
          <div>
            <label for="name">Name</label>
            <input formControlName="name" id="name" type="text" />
          </div>
          <div>
            <label for="pronunciation">Pronunciation</label>
            <input formControlName="pronunciation" id="pronunciation" type="text" />
          </div>
  
          <div>
            <label for="nickname">Nickname</label>
            <input formControlName="nickname" id="nickname" type="url" />
          </div>
          <div>
            <label for="line1">Event 1</label>
            <input formControlName="line1" id="line1" type="text" placeholder="800m PR: 1:50.56 SB: 1:52.35" />
          </div>
          <div>
            <label for="line2">Event 2</label>
            <input formControlName="line2" id="line2" type="text" placeholder="1500m PR: 3:51.76 SB: 3:53.53" />
          </div>
          <div>
            <label for="notes">Card Back</label>
            <input formControlName="notes" id="notes" type="text" placeholder="Was an Indoor All-American" />
          </div>
        
          <div class="buttons">
            <button mat-raised-button type="submit" class="button primary block" [disabled]="loading" color="primary">
              {{ loading ? 'Loading ...' : 'Update' }}
            </button>
          </div>
        </form>
      </div>
  </mat-tab>


  <mat-tab class="tab">
    <ng-template mat-tab-label>
        <a class="label"> Team Info</a> 
    </ng-template>
    <div class="profile">
      <form [formGroup]="updateTeamDataForm" (ngSubmit)="updateTeamData()" class="form-widget">
        <div>
          <label for="email">Email</label>
          <input id="email" type="text" [value]="session.user.email" disabled />
        </div>
        <div>
          <label for="team_name">Team Name</label>
          <input formControlName="team_name" id="team_name" type="text" />
        </div>
        <div>
          <label for="team_mascot">Team Mascot</label>
          <input formControlName="team_mascot" id="team_mascot" type="text" />
        </div>
        <div>
          <label for="coach">Coach(es)</label>
          <input formControlName="coach" id="coach" type="text" />
        </div>
        <div>
          <label for="event">Todays Event(s)</label>
          <input formControlName="event" id="event" type="text" placeholder="800 and 4x4" />
        </div>
        <div class="buttons">
          <button mat-raised-button type="submit" class="button primary block" [disabled]="loading" color="primary">
            {{ loading ? 'Loading ...' : 'Update' }}
          </button>
        </div>
      </form>
    </div>
</mat-tab>-->


      <!--<mat-tab label="Follow {{athlete.name}}">-->
     


     


    
    <!--<mat-tab class="tab">
      <ng-template mat-tab-label>
          <a class="label"> Upload Photo</a> 
      </ng-template>
      <div class="profile">
        <form [formGroup]="updateCardPhotoUrlForm" (ngSubmit)="updateCardPhotoUrl()" class="form-widget">
          <app-card-photo [cardPhotoUrl]="this.cardPhotoUrl" (upload)="updateCardPhoto($event)"> </app-card-photo>
          <div class="buttons">
            <button mat-raised-button type="submit" class="button primary block" [disabled]="loading" color="primary">
              {{ loading ? 'Loading ...' : 'Update' }}
            </button>
          </div>
        </form>
      </div>
  </mat-tab>-->




  <!--TODO Add a method to retrieve profile data



<body>
--><!--CLean This Shit up--><!--
  <div class=" profiles">
   --><!-- Buttons. --><!--
    <button class="button" (click)="buttonClicked=1;">Account</button>
    <button class="button" (click)="buttonClicked=2">Links</button>
    <button class="button" (click)="buttonClicked=3">Card Data</button>
    <button class="button" (click)="buttonClicked=4">Team Info</button>
    <button class="button" (click)="buttonClicked=5">Trading Card</button>
  --><!-- Display if SubContent-1 is enable. --><!--
    <div class="profile" *ngIf="buttonClicked==1">
      <form [formGroup]="updateProfileForm" (ngSubmit)="updateProfile()" class="form-widget">
        <div>
          <label for="email">Email</label>
          <input id="email" type="text" [value]="session.user.email" disabled />
        </div>
        <div>
          <label for="full_name">Name</label>
          <input formControlName="full_name" id="full_name" type="text" />
        </div>

        <div>
          <button type="submit" class="button primary block" [disabled]="loading">
            {{ loading ? 'Loading ...' : 'Update' }}
          </button>
        </div>

      </form>
    </div>

   --><!--Display if SubContent-2 is enable. --><!--
    <div class="profile" *ngIf="buttonClicked==2">
      <form [formGroup]="updateSocialForm" (ngSubmit)="updateSocial()" class="form-widget">
        <div>
          <label for="email">Email</label>
          <input id="email" type="text" [value]="session.user.email" disabled />
        </div>
        <div>
          <label for="strava">Strava</label>
          <input formControlName="strava" id="strava" type="url" />
        </div>
        <div>
          <label for="world_athletics">World Athletics</label>
          <input formControlName="world_athletics" id="world_athletics" type="url" />
        </div>
        <div>
          <label for="milesplit">MileSplit</label>
          <input formControlName="milesplit" id="milesplit" type="url" />
        </div>
        <div>
          <label for="tfrrs">TFRRS</label>
          <input formControlName="tfrrs" id="tfrrs" type="url" />
        </div>
        <div>
          <label for="instagram">Instagram</label>
          <input formControlName="instagram" id="instagram" type="url" />
        </div>


        <div>
          <button type="submit" class="button primary block" [disabled]="loading">
            {{ loading ? 'Loading ...' : 'Update' }}
          </button>
        </div>


      </form>
    </div>
   --><!-- Display if SubContent-3 is enable. --><!--
    <div class="profile" *ngIf="buttonClicked==3">
      <form [formGroup]="updateCardDataForm" (ngSubmit)="updateCardData()" class="form-widget">
        <div>
          <label for="email">Email</label>
          <input id="email" type="text" [value]="session.user.email" disabled />
        </div>
        <div>
          <label for="name">Name</label>
          <input formControlName="name" id="name" type="text" />
        </div>
        <div>
          <label for="pronunciation">Pronunciation</label>
          <input formControlName="pronunciation" id="pronunciation" type="text" />
        </div>

        <div>
          <label for="nickname">Nickname</label>
          <input formControlName="nickname" id="nickname" type="url" />
        </div>
        <div>
          <label for="line1">Event 1</label>
          <input formControlName="line1" id="line1" type="text" placeholder="800m PR: 1:50.56 SB: 1:52.35" />
        </div>
        <div>
          <label for="line2">Event 2</label>
          <input formControlName="line2" id="line2" type="text" placeholder="1500m PR: 3:51.76 SB: 3:53.53" />
        </div>
        <div>
          <label for="notes">Card Back</label>
          <input formControlName="notes" id="notes" type="text" placeholder="Was an Indoor All-American" />
        </div>


        <div>
          <button type="submit" class="button primary block" [disabled]="loading">
            {{ loading ? 'Loading ...' : 'Update' }}
          </button>
        </div>


      </form>
    </div>
  --><!-- Display if SubContent-4 is enable. --><!--
    <div class="profile" *ngIf="buttonClicked==4">
      <form [formGroup]="updateTeamDataForm" (ngSubmit)="updateTeamData()" class="form-widget">
        <div>
          <label for="email">Email</label>
          <input id="email" type="text" [value]="session.user.email" disabled />
        </div>
        <div>
          <label for="team_name">Team Name</label>
          <input formControlName="team_name" id="team_name" type="text" />
        </div>
        <div>
          <label for="team_mascot">Team Mascot</label>
          <input formControlName="team_mascot" id="team_mascot" type="text" />
        </div>
        <div>
          <label for="coach">Coach(es)</label>
          <input formControlName="coach" id="coach" type="text" />
        </div>
        <div>
          <button type="submit" class="button primary block" [disabled]="loading">
            {{ loading ? 'Loading ...' : 'Update' }}
          </button>
        </div>

      </form>
    </div>


  --><!-- Display if SubContent-5 is enable. --><!--
    <div class="profile" *ngIf="buttonClicked==5">
      <h2>Click To See Other Side</h2>
      <app-user-card [userID]="userID"></app-user-card>
    </div>
  </div>
  <div>
    <button class="button block signOut" (click)="signOut()">Sign Out</button>
  </div>
</body>-->