import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TicketingComponent } from '../../shared/components/ticketing/ticketing.component';
import { MatButtonModule } from '@angular/material/button';

@Component({
    selector: 'app-usatf-region-2',
    standalone: true,
    templateUrl: './usatf-region-2.component.html',
    styleUrl: './usatf-region-2.component.css',
    imports: [CommonModule, 
      TicketingComponent,
    MatButtonModule]
})
export class USATFRegion2Component {
  buyTickets= false

  meetInfo = {
    title: 'USATF Region 2 Championship',
    dates: 'July 12-14, 2024',
    location: 'Lincoln Park Track, Jersey City, New Jersey',
    host: 'USATF NJ',
    admission: {
      daily: '$10.00/day (Friday-Saturday-Sunday)',
      pass: '3-Day Pass/$25.00',
      free: 'Athletes with competitor number & children under 12 free'
    },
    packetPickup: [
      { date: 'Thursday, July 11th', time: '1:00pm-6:00pm', location:'Host Hotel' },
      { date: 'Friday, July 12th', time: '8:00am-5:00pm', location:'Lincoln Park' },
      { date: 'Saturday, July 13th', time: '7:00am-3:00pm', location:'Lincoln Park' },
      { date: 'Sunday, July 14th', time: '8:00am-10:00am', location:'Lincoln Park' },

    ],
    hotelInfo: 'Residence Inn in Secaucus Meadowlands 800 Plaza Drive, Secaucus, New Jersey, 07094',
};
}

