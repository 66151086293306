<mat-grid-list cols={{value}} rowHeight="450px" class="grid"><!--Change to row per heat-->
    @for (allUser of allUsers; track allUser ){
    <mat-grid-tile>
        <!--<mat-card class="cards">
            <mat-card-header>
                <div mat-card-avatar class="example-header-image"></div>
               
                <mat-card-title>Events: {{allUser.event}}</mat-card-title>
            </mat-card-header>
            <mat-card-content>-->
                <app-user-card class="card" [sessionSent]=false [userID]="allUser.id"></app-user-card>
           <!-- </mat-card-content>-->
            <!--<mat-card-actions> Add link to athlete profile
  <button mat-button>LIKE</button>
  <button mat-button>SHARE</button>
</mat-card-actions>-->
        <!--</mat-card>-->
    </mat-grid-tile>
    }
</mat-grid-list>