<h1 mat-dialog-title class="dialog-title">Meet Information</h1>
<div class="dialog-content">
    <h3 class="event-info">8:00pm Field Events</h3>
    <h3 class="event-info">8:00pm Track Events</h3>
    <h3 class="event-info">Individual $20 Relay $35</h3>
    <h3 class="event-info">$15 Late Fee Day Of Meet</h3>
  <div class="button-container">
    <a class="button" mat-raised-button color="primary" [href]="data.location.link" target="_blank">{{ data.location.name }}</a>
    <a class="button" mat-raised-button color="primary" [href]="data.timer.link" target="_blank">{{ data.timer.name }}</a>
  </div>
  <div class="close-button-container">
    <button class="button" mat-raised-button color="primary" (click)="closeDialog()">Close</button>
  </div>
</div>