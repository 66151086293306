import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

export interface Event {
  event_id: string;
  meet_id: string;
  name: string;
  description: string;
  fee: number;
  is_flat_fee: boolean;
  created_at: string;
  updated_at: string;
}

export interface EventDTO {
  name: string;
  description: string;
  fee: number;
  isFlatFee: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class EventService {

  constructor(private http: HttpClient) { }

  addEvent(meetId: string, event: EventDTO): Observable<Event> {
    return this.http.post<Event>(`/api/meets/${meetId}/events`, event);
  }

  getEvents(meetId: string): Observable<Event[]> {
    return this.http.get<Event[]>(`/api/meets/${meetId}/events`);
  }

  updateEvent(eventId: string, event: EventDTO): Observable<Event> {
    return this.http.put<Event>(`/api/events/${eventId}`, event);
  }

  deleteEvent(eventId: string): Observable<any> {
    return this.http.delete(`/api/events/${eventId}`);
  }
}