<div class="meet-creation-container">
    <h2>Create a New Track Meet</h2>
    <form [formGroup]="meetForm" (ngSubmit)="onSubmit()">
      <mat-form-field appearance="fill" class="full-width">
        <mat-label>Title</mat-label>
        <input matInput formControlName="title" required>
        <mat-error *ngIf="meetForm.get('title')?.hasError('required')">
          Title is required
        </mat-error>
      </mat-form-field>
  
      <mat-form-field appearance="fill" class="full-width">
        <mat-label>Description</mat-label>
        <textarea matInput formControlName="description" rows="4"></textarea>
      </mat-form-field>
  
      <mat-form-field appearance="fill" class="full-width">
        <mat-label>Location</mat-label>
        <input matInput formControlName="location" required>
        <mat-error *ngIf="meetForm.get('location')?.hasError('required')">
          Location is required
        </mat-error>
      </mat-form-field>
  
      <mat-form-field appearance="fill" class="full-width">
        <mat-label>Date</mat-label>
        <input matInput [matDatepicker]="picker" formControlName="date" required>
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
        <mat-error *ngIf="meetForm.get('date')?.hasError('required')">
          Date is required
        </mat-error>
      </mat-form-field>
  
      <mat-checkbox formControlName="isFlatFee">Apply Flat Fee for Multiple Events</mat-checkbox>
  
      <mat-form-field appearance="fill" class="full-width" *ngIf="isFlatFee">
        <mat-label>Flat Fee Amount ($)</mat-label>
        <input matInput type="number" formControlName="flatFeeAmount">
        <mat-error *ngIf="meetForm.get('flatFeeAmount')?.hasError('min')">
          Fee must be a positive number
        </mat-error>
      </mat-form-field>
  
      <button mat-raised-button color="primary" type="submit" [disabled]="isLoading">
        {{ isLoading ? 'Creating...' : 'Create Meet' }}
      </button>
    </form>
  </div>
  <!--ADD Contact info, Registration end, officials assignment-->