import { Injectable } from '@angular/core';
import { SupabaseClient } from '@supabase/supabase-js';
import { SupabaseService } from '../supabase/supabase.service';

@Injectable({
  providedIn: 'root',
})
export class RegistrationService {
  private supabase: SupabaseClient;

  constructor(private supabaseService: SupabaseService) {
    this.supabase = this.supabaseService.getClient();
  }

  // Save event registration
  saveEventRegistration(allEvents: any, allRelayEvents: any, registrar: any) {
    var update
    update= {
      name: registrar.name,
      email: registrar.email,
      phone: registrar.phone,
      events: allEvents,
      relayEvents: allRelayEvents,
      paid: true
    }
    return this.supabase
      .from('event_registrations')
      .insert(update);
  }
  /*saveEventRegistration(registrationData: any) {
    const newRegistration = {
      ...registrationData,
      paid: true,
      created_at: new Date(),
    };

    return this.supabase.from('event_registrations').insert(newRegistration);
  }*/

  // Save tickets
  saveTickets(friday: any, saturday: any, sunday: any, multiDay: any, registrar: any) {
    var update
    update= {
      name: registrar.name,
      email: registrar.email,
      phone: registrar.phone,
      friday: friday,
      saturday: saturday,
      sunday: sunday,
      multiDay: multiDay,
      paid: true
    }
    return this.supabase
      .from('event_tickets_temp')
      .insert(update);
  }

  /*saveTickets(ticketData: any) {
    const newTickets = {
      ...ticketData,
      paid: true,
      created_at: new Date(),
    };

    return this.supabase.from('event_tickets_temp').insert(newTickets);
  }*/

  // Download event registrations
  downloadEventRegistrations() {
    return this.supabase
      .from('event_registrations')
      .select('events, relayEvents, name, paid, phone, email');
  }

  // Download tickets
  downloadTickets() {
    return this.supabase
      .from('event_tickets_temp')
      .select('name, friday, saturday, sunday, multiDay');
  }

  // Additional registration-related methods...
}