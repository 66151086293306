import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, throwError, of, from } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { AuthenticationService } from '../authentication/authentication.service';

@Injectable({
  providedIn: 'root'
})
export class ImageService {
  private workerUrl = 'https://mosaic-photo-presigned-worker.michael-twist.workers.dev/generate-presigned-url';

  constructor(private http: HttpClient, private authService: AuthenticationService) {}

uploadImage(file: File): Observable<string> {
  console.log('Starting upload for file:', file);
  const fileName = file.name;
  const fileType = file.type;

  // Convert the Promise to an Observable
  return from(this.authService.getAccessToken()).pipe(
    switchMap(token => {
      if (!token) {
        console.error('No Supabase token found');
        return throwError('Authentication token missing. Please log in.');
      }

      console.log('Using Supabase Token:', token); // Remove or secure in production

      const headers = new HttpHeaders({
        Authorization: `Bearer ${token}`
      });

      // Request the pre-signed URL from the Cloudflare Worker
      return this.http.get<{ url: string }>(
        `${this.workerUrl}?fileName=${encodeURIComponent(fileName)}&fileType=${encodeURIComponent(fileType)}`,
        { headers }
      );
    }),
    switchMap(response => {
      const presignedUrl = response.url;
      console.log('Received presigned URL:', presignedUrl);

      // Upload the file directly to MinIO without the Content-Type header
      return this.http.put(presignedUrl, file, {
        responseType: 'text' // MinIO might respond with an empty body
      }).pipe(
        switchMap(() => {
          const uploadedUrl = presignedUrl.split('?')[0]; // Remove query params
          console.log('Uploaded file is accessible at:', uploadedUrl);
          return of(uploadedUrl);
        })
      );
    }),
    catchError(error => {
      console.error('Upload error:', error);
      return throwError(error);
    })
  );
}

  getImageUrl(objectName: string): string {
    // Depending on your setup, construct the image URL
    return `https://images.mosaic-sports.com/user-images/${objectName}`;
  }
}
