<!--TODO CLean this up add user search bar-->

<mat-sidenav-container class="sidenav-container">

  <mat-sidenav #drawer [ngClass]="(isHandset$ | async) ? 'sidenavMobile' : 'sidenav'" fixedInViewport [attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'"
    [mode]="(isHandset$ | async) ? 'over' : 'side'" [opened]="false" position="end" >
    <mat-toolbar>Menu
      <button type="button" aria-label="Toggle sidenav" mat-icon-button (click)="drawer.toggle()">
        <mat-icon aria-label="Side nav toggle icon">close</mat-icon>
      </button>
    </mat-toolbar>
    <mat-nav-list>
      <!--<app-event-box [userID]="userId"></app-event-box>-->
     <app-user-card class="card" [sessionSent]=true [authSession]="session" [userID]="userId"></app-user-card>
      <a mat-list-item href={{userLink}} target="_blank">View Your Profile</a>
      <a mat-list-item href=profile>Update Your Account</a>
      <a mat-list-item (click)="onCopy()" [cdkCopyToClipboard]="userURL" role="button" style="cursor: pointer;">Share Your Profile</a>    
    </mat-nav-list>
    <div class="sign-out">
      <button mat-raised-button class="block signOut" (click)="signOut()" color="warn">Sign Out</button>
    </div>
  </mat-sidenav>
  <mat-sidenav-content>

    @if(session){
    <mat-toolbar class="active" color="primary">
     @if(isHandset$ | async){ <a class="home" href="home"><span>mosaic-sports</span></a>}<!--Change on Mobile-->
     @if(!(isHandset$ | async)){ <a class="home" href="home"><span>mosaic-sports</span></a>}
      <!--<app-progress-bar></app-progress-bar> Not Used Yet-->
      <!--@if (isHandset$ | async) {-->
      <button matBadgePosition="below" matBadgeSize="small" matBadge="1" matBadgeColor="accent" [matBadgeOverlap]=true [matBadgeHidden]="hidden" type="button" aria-label="Toggle sidenav" mat-icon-button (click)="drawer.toggle(); hidden=true">
        <mat-icon aria-label="Side nav toggle icon">menu</mat-icon>
      </button>
    </mat-toolbar>
    } @else {
    <mat-toolbar class="notActive" color="primary">
      <a class="home" href="home"><span>mosaic-sports</span></a>
      <a class="login" href="profile/login">Login</a>
    </mat-toolbar>
    }
    <router-outlet> </router-outlet>

    <app-footer></app-footer>
  </mat-sidenav-content>
</mat-sidenav-container>
