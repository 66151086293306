<div #page>
    @if(type.match("series")){
    <h2 class="series-title">{{seriesInfo.name}}</h2> 
    <p class="description">{{seriesInfo.description}}</p>
    <h3 class=" presented-by-title">Presented by {{seriesSponsor.name}}</h3>
    }
    @if(!register && !showCheckout) {
        <div class="event-registration-container">
    
            <div class="event-dates-container">
               <!-- <button mat-raised-button color="primary" class="register-button" (click)="openEventInfoDialog()">Meet
                    Info</button>
                <button mat-raised-button color="primary" class="register-button" (click)="register = true">Register
                    Now!</button>-->
    
                    <div class="dates-wrapper">
                        <div class="date-section">
                            <h3 class='event-date'>mosaic-sports Twilight #1</h3>
                            <h4 class="event-date">{{ '2024-08-06T00:00:00-21:00' | date: 'fullDate' }}</h4>
                            <ul class="event-list">
                                <li class="event-item">
                                    August 6th Meet Canceled due to forcasted weather!
                                </li>
                            </ul>
                        </div>
                    </div>
    
                    <div class="dates-wrapper">
                        <div class="date-section">
                            <h3 class='event-date'>mosaic-sports Twilight #2</h3>
                            <h4 class="event-date">{{ '2024-08-20T00:00:00-21:00' | date: 'fullDate' }}</h4>
                            <ul class="event-list">
                                <li class="event-item">
                                    August 20th Meet Canceled due to low registration!
                                </li>
                            </ul>
                        </div>
                    </div>
            </div>
        </div>
        }
        @if(!showCheckout && register){  
            <div class="event-dates-container">
        <a mat-raised-button color="primary" class="contact-button" href="contact" target="_blank">Contact Us For Help</a>
        </div>
        <h2 class="section-title">Register Athletes</h2>
        <p class="registration-info">Click Add Athlete and fill out the info for each athlete you intend to register</p>
      
        <div class="form-container">
            <div class="athlete-container">
    
    
                <div *ngFor="let athlete of registeredAthletes; let i = index">
                    <mat-form-field>
                        <mat-label>Name</mat-label>
                        <input matInput type="text" [(ngModel)]="athlete.name" name="name{{ i }}" required>
                    </mat-form-field>
    
                    <mat-form-field>
                        <mat-label>Date of Birth</mat-label>
                        <input matInput [matDatepicker]="dobPicker" [(ngModel)]="athlete.dob" name="dob{{ i }}" required>
                        <mat-datepicker-toggle matSuffix [for]="dobPicker"></mat-datepicker-toggle>
                        <mat-datepicker #dobPicker></mat-datepicker>
                    </mat-form-field>
    
                    <mat-form-field>
                        <mat-label>Gender</mat-label>
                        <mat-select [(ngModel)]="athlete.gender" name="gender{{ i }}" required>
                            <mat-option value="Male">Male</mat-option>
                            <mat-option value="Female">Female</mat-option>
                        </mat-select>
                    </mat-form-field>
    
                    <mat-form-field>
                        <mat-label>Team</mat-label>
                        <input matInput type="team" [(ngModel)]="athlete.team" name="team{{ i }}" required>
                    </mat-form-field>
    
                    <mat-form-field>
                        <mat-label>Contact Email</mat-label>
                        <input matInput type="email" [(ngModel)]="athlete.email" name="email{{ i }}" required>
                    </mat-form-field>
    
                    <mat-form-field>
                        <mat-label>AAU Number</mat-label>
                        <input matInput type="text" [(ngModel)]="athlete.aau" name="aau{{ i }}">
                    </mat-form-field>
    
                    <mat-form-field>
                        <mat-label>USATF Number</mat-label>
                        <input matInput type="text" [(ngModel)]="athlete.usatf" name="usatf{{ i }}">
                    </mat-form-field>
    
                    <button mat-icon-button (click)="deleteAthlete(i)">
                        <mat-icon>delete</mat-icon>
                    </button>
                </div>
                <button mat-raised-button color="primary" type="button" (click)="addAthlete()">Add Athlete</button>
            </div>
        </div>
    
        <h2>Assign Athletes to Events</h2>
        <p class="registration-info">Select the Meet and Event from the drop down. Once you assign every athlete and enter their seeds you can select another event.</p>

    
        <div class="form-container">
            <div class="event-container">
                <div>
                    <!-- Select Meet Date -->
                    <mat-form-field>
                        <mat-label>Select Meet Date</mat-label>
                        <mat-select [(ngModel)]="selectedDate" (selectionChange)="onDateSelect($event.value)">
                            <mat-option *ngFor="let date of availableDates" [value]="date">{{ date | date:"MMM dd, yyyy":"-4" }}</mat-option>
                        </mat-select>
                    </mat-form-field>
    
                    <!-- Select Individual Event -->
                    <mat-form-field *ngIf="selectedDate">
                        <mat-label>Select Individual Event</mat-label>
                        <mat-select [(ngModel)]="selectedEvent" name="selectedEvent"
                            (selectionChange)="onEventSelect($event.value)">
                            <mat-option *ngFor="let event of filteredEvents" [value]="event">{{ event.name }} - {{
                                event.date |
                                date:"MMM dd, yyyy":"-4"}}</mat-option>
                        </mat-select>
                    </mat-form-field>
    
                    <!-- Select Relay Event -->
                    <mat-form-field *ngIf="selectedDate && registeredAthletes.length >= 4">
                        <mat-label>Select Relay Event</mat-label>
                        <mat-select [(ngModel)]="selectedRelayEvent" name="selectedRelayEvent"
                            (selectionChange)="onRelayEventSelect($event.value)">
                            <mat-option *ngFor="let event of filteredRelayEvents" [value]="event">{{ event.name }} - {{
                                event.date | date }}</mat-option>
                        </mat-select>
                    </mat-form-field>
    
                    <!-- Individual Event Registration Form -->
                    <div *ngIf="selectedEvent">
                        <h3>{{ selectedEvent.name }} - {{ selectedEvent.date | date:"MMM dd, yyyy":"-4" }}</h3>
    
                        <div *ngFor="let athlete of registeredAthletes">
                            <button *ngIf="isAthleteEligibleForEvent(selectedEvent, athlete)&&isAthleteDataComplete(athlete)" mat-raised-button color="primary" type="button"
                                (click)="assignAthleteToEvent(selectedEvent, athlete)">Assign {{ athlete.name }}</button>
                        </div>
    
                        <ul>
                            <li *ngFor="let athlete of selectedEvent.athletes; let i = index">
                                {{ athlete.name }} - {{ athlete.dob | date }} - {{ athlete.gender }} - {{ athlete.email }} -
                                {{
                                athlete.aau }} - {{ athlete.usatf }}
                                <mat-form-field>
                                    <mat-label>Seed Mark</mat-label>
                                    <input matInput type="text" [(ngModel)]="athlete.seedMark"
                                        name="seedMark{{ selectedEvent.name }}{{ i }}" required>
                                        <mat-hint>Field event marks should be in metric</mat-hint>
                                </mat-form-field>
                                <button mat-icon-button (click)="deleteAssignedAthlete(selectedEvent, i)">
                                    <mat-icon>delete</mat-icon>
                                </button>
                                <button mat-icon-button (click)="startEditAthlete(selectedEvent, athlete, i)">
                                    <mat-icon>edit</mat-icon>
                                </button>
                            </li>
                        </ul>
                    </div>
                    <!-- Relay Event Registration Form -->
                    <div *ngIf="selectedRelayEvent">
                        <h3>{{ selectedRelayEvent.name }} - {{ selectedRelayEvent.date | date }}</h3>
    
                        <div *ngFor="let athlete of registeredAthletes">
                            <button mat-raised-button color="primary" type="button"
                                *ngIf="isAthleteEligibleForRelay(selectedRelayEvent, athlete)&&isAthleteDataComplete(athlete)"
                                (click)="assignAthleteToRelayEvent(selectedRelayEvent, athlete)">Assign {{ athlete.name
                                }}</button>
                        </div>
    
                        <ul>
                            <li *ngFor="let athlete of selectedRelayEvent.athletes; let i = index">
                                {{ athlete.name }} - {{ athlete.dob | date }} - {{ athlete.gender }} - {{ athlete.email }} -
                                {{
                                athlete.aau }} - {{ athlete.usatf }}
                                <button mat-icon-button (click)="deleteAssignedRelayAthlete(selectedRelayEvent, i)">
                                    <mat-icon>delete</mat-icon>
                                </button>
                                <button mat-icon-button (click)="startEditAthlete(selectedRelayEvent, athlete, i)">
                                    <mat-icon>edit</mat-icon>
                                </button>
                            </li>
                            <li>
                                Seed Mark: <input matInput type="text" [(ngModel)]="selectedRelayEvent.seedMark"
                                    name="seedMark{{ selectedRelayEvent.name }}" required>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    
        <!-- Edit Athlete Form -->
        <div *ngIf="editingAthlete !== null">
            <h3>Edit Athlete</h3>
            <form (ngSubmit)="updateAthlete()">
                <mat-form-field>
                    <mat-label>Name</mat-label>
                    <input matInput [(ngModel)]="editingAthlete.name" name="name" required>
                </mat-form-field>
                <mat-form-field>
                    <mat-label>Date of Birth</mat-label>
                    <input matInput [(ngModel)]="editingAthlete.dob" [matDatepicker]="picker" name="dob" required>
                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-datepicker #picker></mat-datepicker>
                </mat-form-field>
                <mat-form-field>
                    <mat-label>Gender</mat-label>
                    <mat-select [(ngModel)]="editingAthlete.gender" name="gender" required>
                        <mat-option value="Male">Male</mat-option>
                        <mat-option value="Female">Female</mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field>
                    <mat-label>Contact Email</mat-label>
                    <input matInput [(ngModel)]="editingAthlete.email" name="email" required>
                </mat-form-field>
                <mat-form-field>
                    <mat-label>AAU Number</mat-label>
                    <input matInput [(ngModel)]="editingAthlete.aau" name="aau">
                </mat-form-field>
                <mat-form-field>
                    <mat-label>USATF Number</mat-label>
                    <input matInput [(ngModel)]="editingAthlete.usatf" name="usatf">
                </mat-form-field>
                <button mat-raised-button color="primary" type="submit">Update</button>
                <button mat-raised-button color="warn" type="button" (click)="cancelEdit()">Cancel</button>
            </form>
        </div>
    
        <!-- Registrar Information Form -->
        <h2 class="section-title">Registrar Information</h2>
        <p class="registration-info">Enter your information so we can contact you if we have any questions regarding registrations. All fields with an * must be filled out before the submit button will appear.</p>

        <div class="form-container">
            <div class="registrar-container">
                <div>
                    <mat-form-field>
                        <mat-label>Name</mat-label>
                        <input matInput type="text" [(ngModel)]="registrar.name" name="registrarName" required>
                    </mat-form-field>
                    <mat-form-field>
                        <mat-label>Email</mat-label>
                        <input matInput type="email" [(ngModel)]="registrar.email" name="registrarEmail" required>
                    </mat-form-field>
                    <mat-form-field>
                        <mat-label>Phone Number</mat-label>
                        <input matInput type="tel" [(ngModel)]="registrar.phone" name="registrarPhone" required>
                    </mat-form-field>
                </div>
            </div>
        </div>
        <!-- Display all entered athletes -->
        @if(hasEntries()){
        <h2>All Entered Athletes</h2>
        <div class="form-container">
            <div class="entered-athletes-container">
    
                <!-- Display Individual Event Athletes -->
                <div *ngFor="let event of getEnteredEvents()">
                    <h3>{{ event.name }} - {{ event.date | date:"MMM dd, yyyy":"-4" }}</h3>
                    <ul>
                        <li *ngFor="let athlete of event.athletes; let i = index">
                            Name: {{ athlete.name }}, DOB: {{ athlete.dob | date }}, Gender: {{ athlete.gender }}, Email: {{
                            athlete.email }}, AAU Number: {{athlete.aau}}, USATF Number: {{athlete.usatf}}, Seed Mark: {{
                            athlete.seedMark }}
                            <button mat-icon-button (click)="deleteAssignedAthlete(event, i)">
                                <mat-icon>delete</mat-icon>
                            </button>
                            <button mat-icon-button (click)="startEditAthlete(event, athlete, i)">
                                <mat-icon>edit</mat-icon>
                            </button>
                        </li>
                    </ul>
                </div>
                <!-- Display Relay Event Athletes -->
                <div *ngFor="let relayEvent of getEnteredRelayEvents()">
                    <h3>{{ relayEvent.name }} - {{ relayEvent.date | date }}</h3>
                    <ul>
                        <li *ngFor="let athlete of relayEvent.athletes; let i = index">
                            Name: {{ athlete.name }}, DOB: {{ athlete.dob | date }}, Gender: {{ athlete.gender }}, Email: {{
                            athlete.email }}, AAU Number: {{athlete.aau}}, USATF Number: {{athlete.usatf}}
                            <button mat-icon-button (click)="deleteAssignedRelayAthlete(relayEvent, i)">
                                <mat-icon>delete</mat-icon>
                            </button>
                            <button mat-icon-button (click)="startEditAthlete(relayEvent, athlete, i)">
                                <mat-icon>edit</mat-icon>
                            </button>
                        </li>
                        <li>Seed Mark: {{ relayEvent.seedMark }}</li>
                    </ul>
                </div>
            </div>
        </div>
        }
    
        <button mat-raised-button color="primary" type="submit" (click)="onSubmit()" class="submit" *ngIf="hasEntries()&& isRegistrarInfoComplete()">
            Submit All Registrations
        </button>
        <!-- Success and Error Messages -->
        <div *ngIf="successMessage" class="alert alert-success">
            {{ successMessage }}
        </div>
        <div *ngIf="errorMessage" class="alert alert-danger">
            {{ errorMessage }}
        </div>
        }
        @else if (showCheckout) {
            @if(!checkedout){
        <div class="back-button-container">
            <button mat-icon-button (click)="showCheckout = false; page.scrollTop" class="back-button">
                <mat-icon class="back">undo</mat-icon>
            </button>
            <p class="warning-message">Do Not Use Your Browser's Back Button!</p>
        </div>
    }@else {
        <div class="back-button-container">
        <p class="warning-message">Your Entries Will Be Emailed to You!</p>
    </div>
    }
        <app-embedded-checkout [data]="data" (checkedoutChange)="onCheckoutCompleted($event)"></app-embedded-checkout>
        }
    </div>