import { Component, OnInit } from '@angular/core';
import { SupabaseService } from '../../../services/supabase/supabase.service';
import { CommonModule } from '@angular/common';
import { RegistrationService } from '../../../services/registration/registration.service';

@Component({
  selector: 'app-ticket-viewer',
  standalone: true,
  imports: [
    CommonModule
  ],
  templateUrl: './ticket-viewer.component.html',
  styleUrls: ['./ticket-viewer.component.css']
})
export class TicketViewerComponent implements OnInit {

  errorMessage: string | null = null;
  tickets: any;

  constructor(
    private readonly registrationServices: RegistrationService
  ) {}

  ngOnInit() {
    this.loadTicketsFromSupabase();
  }

  async loadTicketsFromSupabase() {
    const { data: tickets, error } = await this.registrationServices.downloadTickets();
    if (error) {
      this.errorMessage = 'Failed to load events';
      return;
    }
    this.tickets = tickets.sort((a: any, b: any) => a.name.localeCompare(b.name));
  }
}