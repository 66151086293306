import { Component, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TicketingService } from '../../services/ticketing.service';
import { FormsModule, NgForm } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { EmbeddedTicketCheckoutComponent } from '../embedded-ticket-checkout/embedded-ticket-checkout.component';
import { MatInputModule } from '@angular/material/input';

@Component({
  selector: 'app-ticketing',
  standalone: true,
  imports: [
  MatFormFieldModule,
  CommonModule,
  MatSelectModule,
  FormsModule,
  MatButtonModule,
  MatIconModule,
  EmbeddedTicketCheckoutComponent,
  MatInputModule

  
],
  templateUrl: './ticketing.component.html',
  styleUrl: './ticketing.component.css'
})
export class TicketingComponent {
  ticketOptions = [
    //{ day: 'Friday', value: 'friday', selected: 0 },
    { day: 'Saturday', value: 'saturday', selected: 0 },
    { day: 'Sunday', value: 'sunday', selected: 0 },
  ];
  registrar = {
    name: '',
    email: '',
    phone: ''
  };
  checkedout=false;
  showCheckout = false;
  multiDayPass = { selected: 0 };

  prices = {
    singleDay: 10,
    multiDay: 25
  };

  fees = {
    singleDay: 2.35,
    multiDay: 3.25
  };
  data: any[] = []; // Add this property to hold the items for checkout

  get totalTickets() {
    return this.ticketOptions.reduce((acc, option) => acc + option.selected, this.multiDayPass.selected);
  }

  get totalCost() {
    const singleDayTickets = this.ticketOptions.reduce((acc, option) => acc + option.selected, 0);
    const singleDayCost = singleDayTickets * this.prices.singleDay;
    const multiDayCost = this.multiDayPass.selected * this.prices.multiDay;
    const singleDayFees = singleDayTickets * this.fees.singleDay;
    const multiDayFees = this.multiDayPass.selected * this.fees.multiDay;

    return singleDayCost + multiDayCost + singleDayFees + multiDayFees;
  }

  updateTicket(option: any, value: number) {
    option.selected = value;
  }

  buyTickets() {
    this.data.push({
      friday: this.ticketOptions.find(option => option.value === 'friday')?.selected || 0,
      saturday: this.ticketOptions.find(option => option.value === 'saturday')?.selected || 0,
      sunday: this.ticketOptions.find(option => option.value === 'sunday')?.selected || 0,
      multiDay: this.multiDayPass.selected,
      registrar: this.registrar // Include registrar information
    });
    console.log(JSON.stringify(this.data));
    this.showCheckout = true;
    console.log('Tickets purchased:', this.ticketOptions, this.multiDayPass);
    console.log('Total Cost:', this.totalCost);
  }

  isRegistrarInfoComplete(): boolean {
    return !!(this.registrar.name && this.registrar.email && this.registrar.phone);
  }

  onCheckoutCompleted(checkedout: boolean) {
    if (checkedout) {
      this.checkedout=true;
      console.log('Checkout completed:', checkedout);
      // Additional logic to run after checkout is completed
    }
  }
}