import { v4 as uuidv4 } from 'uuid';
import { Component, OnInit } from '@angular/core';
import { EventRegistrationService } from '../../services/event-registration.service';
import { Event, Athlete, RelayEvent, RegisteredAthlete } from '../../models/event.model';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { MatSelectModule } from '@angular/material/select';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatCardModule } from '@angular/material/card';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatIconModule } from '@angular/material/icon';
import { EmbeddedCheckoutComponent } from "../embedded-checkout/embedded-checkout.component";
import { MatDialog } from '@angular/material/dialog';
import { EventInfoComponent } from '../event-info/event-info.component';
import { ActivatedRoute } from '@angular/router';
import { SponsorService } from '../../../services/sponsors/sponsors.service';
import { EventService } from '../../../services/event/event.service';
import { EventInfo, SubEventInfo } from '../../../models/event/event.model';
import { SeriesInfo, Sponsor } from '../../../models/sponsor/sponsor.model';


@Component({
  selector: 'app-event-registration',
  standalone: true,
  templateUrl: './event-registration.component.html',
  styleUrl: './event-registration.component.css',
  imports: [
    CommonModule,
    FormsModule,
    MatSelectModule,
    MatButtonModule,
    MatInputModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatCardModule,
    MatIconModule,
    EmbeddedCheckoutComponent,
  ]
})
export class EventRegistrationComponent implements OnInit {
  allEvents: Event[] = [
    //{ name: '400mH', date: new Date('2024-06-25T00:00:00-21:00'), price: 'prod_QM7QOvrSkCjjYr', athletes: [] },
    //{ name: '50m', date: new Date('2024-06-25T00:00:00-21:00'), price: 'prod_QM7QOvrSkCjjYr', athletes: [] },
    //{ name: '100m', date: new Date('2024-06-25T00:00:00-21:00'), price: 'prod_QM7QOvrSkCjjYr', athletes: [] },
    //{ name: '1500m', date: new Date('2024-06-25T00:00:00-21:00'), price: 'prod_QM7QOvrSkCjjYr', athletes: [] },
    //{ name: '400m', date: new Date('2024-06-25T00:00:00-21:00'), price: 'prod_QM7QOvrSkCjjYr', athletes: [] },
    //{ name: '100mH/110mH', date: new Date('2024-06-25T00:00:00-21:00'), price: 'prod_QM7QOvrSkCjjYr', athletes: [] },
    //{ name: '800m', date: new Date('2024-06-25T00:00:00-21:00'), price: 'prod_QM7QOvrSkCjjYr', athletes: [] },
    //{ name: '200m', date: new Date('2024-06-25T00:00:00-21:00'), price: 'prod_QM7QOvrSkCjjYr', athletes: [] },
    //{ name: '1500RW', date: new Date('2024-06-25T00:00:00-21:00'), price: 'prod_QM7QOvrSkCjjYr', athletes: [] },
    //{ name: 'Triple Jump', date: new Date('2024-06-25T00:00:00-21:00'), price: 'prod_QM7QOvrSkCjjYr', athletes: [] },
    //{ name: 'Long Jump', date: new Date('2024-06-25T00:00:00-21:00'), price: 'prod_QM7QOvrSkCjjYr', athletes: [] },
    //{ name: 'Shot Put', date: new Date('2024-06-25T00:00:00-21:00'), price: 'prod_QM7QOvrSkCjjYr', athletes: [] },
    //{ name: '50m', date: new Date('2024-07-02T00:00:00-21:00'), price: 'prod_QM7QOvrSkCjjYr', athletes: [] },
    //{ name: '100m', date: new Date('2024-07-02T00:00:00-21:00'), price: 'prod_QM7QOvrSkCjjYr', athletes: [] },
    //{ name: '400m', date: new Date('2024-07-02T00:00:00-21:00'), price: 'prod_QM7QOvrSkCjjYr', athletes: [] },
    //{ name: '100mH/110mH', date: new Date('2024-07-02T00:00:00-21:00'), price: 'prod_QM7QOvrSkCjjYr', athletes: [] },
    //{ name: 'Mile', date: new Date('2024-07-02T00:00:00-21:00'), price: 'prod_QM7QOvrSkCjjYr', athletes: [] },
    //{ name: '200m', date: new Date('2024-07-02T00:00:00-21:00'), price: 'prod_QM7QOvrSkCjjYr', athletes: [] },
    //{ name: 'Triple Jump', date: new Date('2024-07-02T00:00:00-21:00'), price: 'prod_QM7QOvrSkCjjYr', athletes: [] },
    //{ name: 'Long Jump', date: new Date('2024-07-02T00:00:00-21:00'), price: 'prod_QM7QOvrSkCjjYr', athletes: [] },
    //{ name: 'Shot Put', date: new Date('2024-07-02T00:00:00-21:00'), price: 'prod_QM7QOvrSkCjjYr', athletes: [] },
    //{ name: '1500m', date: new Date('2024-07-09T00:00:00-21:00'), price: 'prod_QM7QOvrSkCjjYr', athletes: [] },
    //{ name: '400mH', date: new Date('2024-07-09T00:00:00-21:00'), price: 'prod_QM7QOvrSkCjjYr', athletes: [] },
    //{ name: '5 and Under 50m', date: new Date('2024-07-09T00:00:00-21:00'), price: 'prod_QM7QOvrSkCjjYr', athletes: [] },
    //{ name: '100m', date: new Date('2024-07-09T00:00:00-21:00'), price: 'prod_QM7QOvrSkCjjYr', athletes: [] },
    //{ name: '800m', date: new Date('2024-07-09T00:00:00-21:00'), price: 'prod_QM7QOvrSkCjjYr', athletes: [] },
    //{ name: '200m', date: new Date('2024-07-09T00:00:00-21:00'), price: 'prod_QM7QOvrSkCjjYr', athletes: [] },
    //{ name: '1500mRW', date: new Date('2024-07-09T00:00:00-21:00'), price: 'prod_QM7QOvrSkCjjYr', athletes: [] },
    //{ name: 'Triple Jump', date: new Date('2024-07-09T00:00:00-21:00'), price: 'prod_QM7QOvrSkCjjYr', athletes: [] },
    //{ name: 'Long Jump', date: new Date('2024-07-09T00:00:00-21:00'), price: 'prod_QM7QOvrSkCjjYr', athletes: [] },
    //{ name: 'Shot Put', date: new Date('2024-07-09T00:00:00-21:00'), price: 'prod_QM7QOvrSkCjjYr', athletes: [] },
    //{ name: '1600mRW', date: new Date('2024-07-16T00:00:00-21:00'), price: 'prod_Q92vaOP8GgXumd', athletes: [] },
    //{ name: '100mH/110mH', date: new Date('2024-07-16T00:00:00-21:00'), price: 'prod_Q92vaOP8GgXumd', athletes: [] },
    //{ name: '5 and Under 50m', date: new Date('2024-07-16T00:00:00-21:00'), price: 'prod_Q92vaOP8GgXumd', athletes: [] },
    //{ name: '100m', date: new Date('2024-07-16T00:00:00-21:00'), price: 'prod_Q92vaOP8GgXumd', athletes: [] },
    //{ name: '400m', date: new Date('2024-07-16T00:00:00-21:00'), price: 'prod_Q92vaOP8GgXumd', athletes: [] },
    //{ name: 'Mile', date: new Date('2024-07-16T00:00:00-21:00'), price: 'prod_Q92vaOP8GgXumd', athletes: [] },
    //{ name: 'TJ', date: new Date('2024-07-16T00:00:00-21:00'), price: 'prod_Q92vaOP8GgXumd', athletes: [] },
    //{ name: 'LJ', date: new Date('2024-07-16T00:00:00-21:00'), price: 'prod_Q92vaOP8GgXumd', athletes: [] },
    //{ name: '400mH', date: new Date('2024-07-23T00:00:00-21:00'), price: 'prod_QM7QOvrSkCjjYr', athletes: [] },
    //{ name: '5 and Under 50m', date: new Date('2024-07-23T00:00:00-21:00'), price: 'prod_QM7QOvrSkCjjYr', athletes: [] },
    //{ name: '100m', date: new Date('2024-07-23T00:00:00-21:00'), price: 'prod_QM7QOvrSkCjjYr', athletes: [] },
    //{ name: '800m', date: new Date('2024-07-23T00:00:00-21:00'), price: 'prod_QM7QOvrSkCjjYr', athletes: [] },
    //{ name: '200m', date: new Date('2024-07-23T00:00:00-21:00'), price: 'prod_QM7QOvrSkCjjYr', athletes: [] },
    //{ name: '1500m', date: new Date('2024-07-23T00:00:00-21:00'), price: 'prod_QM7QOvrSkCjjYr', athletes: [] },
    //{ name: '1500mRW', date: new Date('2024-07-23T00:00:00-21:00'), price: 'prod_Q92vaOP8GgXumd', athletes: [] },
    //{ name: '400m', date: new Date('2024-07-23T00:00:00-21:00'), price: 'prod_QM7QOvrSkCjjYr', athletes: [] },
    //{ name: 'Triple Jump', date: new Date('2024-07-23T00:00:00-21:00'), price: 'prod_QM7QOvrSkCjjYr', athletes: [] },
    //{ name: 'Long Jump', date: new Date('2024-07-23T00:00:00-21:00'), price: 'prod_QM7QOvrSkCjjYr', athletes: [] },
    //{ name: 'Shot Put', date: new Date('2024-07-23T00:00:00-21:00'), price: 'prod_QM7QOvrSkCjjYr', athletes: [] },
    //{ name: '400mH', date: new Date('2024-07-30T00:00:00-21:00'), price: 'prod_QM7QOvrSkCjjYr', athletes: [] },
    //{ name: '5 and Under 50m', date: new Date('2024-07-30T00:00:00-21:00'), price: 'prod_QM7QOvrSkCjjYr', athletes: [] },
    //{ name: '100m', date: new Date('2024-07-30T00:00:00-21:00'), price: 'prod_QM7QOvrSkCjjYr', athletes: [] },
    //{ name: '1500m', date: new Date('2024-07-30T00:00:00-21:00'), price: 'prod_QM7QOvrSkCjjYr', athletes: [] },
    //{ name: '400m', date: new Date('2024-07-30T00:00:00-21:00'), price: 'prod_QM7QOvrSkCjjYr', athletes: [] },
    //{ name: '100mH/110mH', date: new Date('2024-07-30T00:00:00-21:00'), price: 'prod_QM7QOvrSkCjjYr', athletes: [] },
    //{ name: '800m', date: new Date('2024-07-30T00:00:00-21:00'), price: 'prod_QM7QOvrSkCjjYr', athletes: [] },
    //{ name: '200m', date: new Date('2024-07-30T00:00:00-21:00'), price: 'prod_QM7QOvrSkCjjYr', athletes: [] },
    //{ name: '1500mRW', date: new Date('2024-07-30T00:00:00-21:00'), price: 'prod_Q92vaOP8GgXumd', athletes: [] },
    //{ name: 'Triple Jump', date: new Date('2024-07-30T00:00:00-21:00'), price: 'prod_QM7QOvrSkCjjYr', athletes: [] },
    //{ name: 'Long Jump', date: new Date('2024-07-30T00:00:00-21:00'), price: 'prod_QM7QOvrSkCjjYr', athletes: [] },
    //{ name: 'Shot Put', date: new Date('2024-07-30T00:00:00-21:00'), price: 'prod_QM7QOvrSkCjjYr', athletes: [] }
    // Add other events as needed
  ];

  allRelayEvents: RelayEvent[] = [

    //{ name: 'SMR(2 2 4 8)', date: new Date('2024-07-23T00:00:00-21:00'), price: 'prod_QM7RlFVaTERWF1', athletes: [], seedMark: '' },
    //{ name: 'SMR(2 2 4 8)', date: new Date('2024-07-02T00:00:00-21:00'), price: 'prod_QM7RlFVaTERWF1', athletes: [], seedMark: '' },
    //{ name: 'Mixed 4x400(2 Boys, 2 Girls)', date: new Date('2024-07-30T00:00:00-21:00'), price: 'prod_QM7RlFVaTERWF1', athletes: [], seedMark: '' },
    //{ name: '4x200m', date: new Date('2024-07-16T00:00:00-21:00'), price: 'prod_Q92vFRUVVJrY6Z', athletes: [], seedMark: '' },
    //{ name: 'Swedish Relay(1 2 3 4)', date: new Date('2024-07-16T00:00:00-21:00'), price: 'prod_Q92vFRUVVJrY6Z', athletes: [], seedMark: '' },
    //{ name: '4x100m', date: new Date('2024-07-09T00:00:00-21:00'), price: 'prod_QM7RlFVaTERWF1', athletes: [], seedMark: '' },
    //{ name: '4x400m', date: new Date('2024-06-25T00:00:00-21:00'), price: 'prod_QM7RlFVaTERWF1', athletes: [], seedMark: '' },
   // { name: '4x400m', date: new Date('2024-06-25T00:00:00-21:00'), price: 'prod_QM7RlFVaTERWF1', athletes: [], seedMark: '' },
    // Add other relay events as needed
  ];
  registeredAthletes: RegisteredAthlete[] = [];
  selectedDate: Date | null = null;
  filteredEvents: Event[] = [];
  selectedEvent: Event | null = null;
  filteredRelayEvents: RelayEvent[] = [];
  selectedRelayEvent: RelayEvent | null = null;
  availableDates: Date[] = [];
  groupedEventsByDate: { [date: string]: (Event | RelayEvent)[] } = {};
  editingAthleteIndex: number | null = null;
  editingAthlete: RegisteredAthlete | null = null;
  successMessage: string | null = null;
  errorMessage: string | null = null;
  data: any[] = []; // Add this property to hold the items for checkout
  showCheckout = false;
  register = false
  registrar = {
    name: '',
    email: '',
    phone: ''
  };
  checkedout=false;

  id="89fe5d60-985f-46fe-99c3-437100147765"
  type="";
  loading= false;
  seriesInfo!: SeriesInfo;
  eventInfo!: EventInfo[];
  seriesSponsor!: Sponsor;
  subEventInfo!: SubEventInfo[];
  event_id!: string[];

  constructor(
    private readonly sponsorService: SponsorService,
    private readonly eventService: EventService,
    private eventRegistrationService: EventRegistrationService, 
    public dialog: MatDialog, 
    private route: ActivatedRoute) { }
//working but I need to pass meet name and ID through to help me with receipts and storing in database
  async ngOnInit() {
    await this.getEventInfo();
    await this.getEvents();
    await this.getSubEvents();
    this.populateEvents();
    this.initializeAvailableDates();
    this.groupEventsByDate();
  }

  async getEventInfo(){
    this.route.params.subscribe(params => {
      //this.id = params['id'];
      this.type = this.route.snapshot.url[0].path === 'series' ? 'series' : 'event';
  });
  if (this.type === 'series') {
   await this.getSeriesInfo();
  } else {
    // Load event details using this.id
  }
}

  async getSeriesInfo(){
  try {
    this.loading = true
    const { data: seriesInfo, error, status } = await this.sponsorService.getSeriesInfo(this.id)
    console.log(seriesInfo)

    if (error && status !== 406) {
      throw error
    }

    if (seriesInfo) {
      this.seriesInfo = seriesInfo
      console.log(this.seriesInfo)
    }
  } catch (error) {
    if (error instanceof Error) {
      alert(error.message)
    }
  } finally {
    this.loading = false
  }

  try {
    this.loading = true
    const { data: sponsor, error, status } = await this.sponsorService.getSponsor(this.seriesInfo.sponsor_id)
    console.log(sponsor)

    if (error && status !== 406) {
      throw error
    }

    if (sponsor) {
      this.seriesSponsor = sponsor
      console.log(this.seriesSponsor)
    }
  } catch (error) {
    if (error instanceof Error) {
      alert(error.message)
    }
  } finally {
    this.loading = false
  }

  try {
    this.loading = true
    const { data: event_id, error, status } = await this.sponsorService.getSeriesEvents(this.id)
    console.log(event_id)

    if (error && status !== 406) {
      throw error
    }

    if (event_id) {
      this.event_id = event_id.map(event => event.id)
      console.log(this.event_id)
    }
  } catch (error) {
    if (error instanceof Error) {
      alert(error.message)
    }
  } finally {
    this.loading = false
  }
}

  async getEvents(){
  try {
    this.loading = true
    const { data: eventInfo, error, status } = await this.eventService.getEventInfo(this.event_id)
    console.log("events",eventInfo)

    if (error && status !== 406) {
      throw error
    }

    if (eventInfo) {
      this.eventInfo = eventInfo
      console.log("helper",this.eventInfo)
    }
  } catch (error) {
    if (error instanceof Error) {
      alert(error.message)
    }
  } finally {
    this.loading = false
  }
}

async getSubEvents(){
  try {
    this.loading = true
    const { data: subEventInfo, error, status } = await this.eventService.getSubEvents(this.event_id)
    console.log(subEventInfo)

    if (error && status !== 406) {
      throw error
    }

    if (subEventInfo) {
      this.subEventInfo = subEventInfo
      console.log("sub",this.subEventInfo)
    }
  } catch (error) {
    if (error instanceof Error) {
      alert(error.message)
    }
  } finally {
    this.loading = false
  }
}

populateEvents() {
  // Create a map to store event details by ID for quick access
  const eventDetailsMap = new Map<string, { date: Date, price: string, name: string }>();

  // Populate the eventDetailsMap with EventInfo data
  this.eventInfo.forEach(ei => {
    eventDetailsMap.set(ei.id, { date: new Date(ei.start_date), price: ei.open_price, name: ei.event_name });
  });

  // Create Event objects from SubEventInfo and use the date, price, and meet name from EventInfo
  this.allEvents = this.subEventInfo.map(sei => {
    const eventDetails = eventDetailsMap.get(sei.event_id) || { date: new Date(), price: '', name: '' };
    return {
      id: sei.id,
      name: sei.event_name,
      meet_name: eventDetails.name,
      date: eventDetails.date,
      price: eventDetails.price,
      athletes: sei.athletes || []
    };
  });
}


  initializeAvailableDates() {
    const uniqueDates = new Set<string>();
    this.allEvents.forEach(event => uniqueDates.add(event.date.toISOString()));
    this.allRelayEvents.forEach(event => uniqueDates.add(event.date.toISOString()));
    this.availableDates = Array.from(uniqueDates).map(dateString => new Date(dateString));
  }

  groupEventsByDate() {
    this.groupedEventsByDate = {};
    this.allEvents.forEach(event => {
      const date = event.date.toDateString();
      if (!this.groupedEventsByDate[date]) {
        this.groupedEventsByDate[date] = [];
      }
      this.groupedEventsByDate[date].push(event);
    });

    this.allRelayEvents.forEach(relayEvent => {
      const date = relayEvent.date.toDateString();
      if (!this.groupedEventsByDate[date]) {
        this.groupedEventsByDate[date] = [];
      }
      this.groupedEventsByDate[date].push(relayEvent);
    });
  }

  onDateSelect(date: Date) {
    this.selectedEvent = null;
    this.filteredEvents = this.allEvents.filter(event => event.date.toDateString() === date.toDateString());
    this.selectedRelayEvent = null;
    this.filteredRelayEvents = this.allRelayEvents.filter(event => event.date.toDateString() === date.toDateString());
  }

  addAthlete() {
    this.registeredAthletes.push({ id: uuidv4(), name: '', dob: new Date(), gender: '', email: '', team: '', aau: '', usatf: '' });
  }

  deleteAthlete(index: number) {
    this.registeredAthletes.splice(index, 1);
  }

  deleteAssignedAthlete(event: Event, index: number) {
    event.athletes.splice(index, 1);
  }

  deleteAssignedRelayAthlete(event: RelayEvent, index: number) {
    event.athletes.splice(index, 1);
  }

  startEditAthlete(event: Event | RelayEvent, athlete: RegisteredAthlete, index: number) {
    this.editingAthleteIndex = index;
    this.editingAthlete = { ...athlete };
  }

  updateAthlete() {
    if (this.editingAthleteIndex !== null && this.editingAthlete !== null) {
      const athleteIndex = this.editingAthleteIndex;
      const athlete = this.registeredAthletes[athleteIndex];
      Object.assign(athlete, this.editingAthlete);
      this.editingAthleteIndex = null;
      this.editingAthlete = null;
    }
  }

  cancelEdit() {
    this.editingAthleteIndex = null;
    this.editingAthlete = null;
  }

  onEventSelect(event: Event) {
    this.selectedEvent = event;
    this.selectedRelayEvent = null;
  }

  onRelayEventSelect(event: RelayEvent) {
    this.selectedRelayEvent = event;
    this.selectedEvent = null;
  }

  isAthleteDataComplete(athlete: RegisteredAthlete): boolean {
    console.log(!!athlete.name && !!athlete.dob && !!athlete.gender && !!athlete.email && !!athlete.team)
    return (!!athlete.name && !!athlete.dob && !!athlete.gender && !!athlete.email && !!athlete.team);
  }

  assignAthleteToEvent(event: Event, athlete: RegisteredAthlete) {
    event.athletes.push({ ...athlete, seedMark: '' });
  }

  assignAthleteToRelayEvent(event: RelayEvent, athlete: RegisteredAthlete) {
    if (event.athletes.length < 4 && !event.athletes.some(a => a.id === athlete.id)) {
      event.athletes.push({ ...athlete });
    } else {
      this.errorMessage = 'Each relay team must have exactly 4 different athletes.';
    }
  }

  isAthleteEligibleForRelay(event: RelayEvent, athlete: RegisteredAthlete): boolean {
    return event.athletes.length < 4 && !event.athletes.some(a => a.id === athlete.id);
  }

  isAthleteEligibleForEvent(event: Event, athlete: RegisteredAthlete): boolean {
    return !event.athletes.some(a => a.id === athlete.id);
  }

  isRegistrarInfoComplete(): boolean {
    return !!(this.registrar.name && this.registrar.email && this.registrar.phone);
  }

  onSubmit() {
    this.data = [];
    this.eventRegistrationService.clear();
    try {
      for (let event of this.allEvents) {
        if (event.athletes.length > 0) {
          this.eventRegistrationService.addEvent(event);
        }
      }

      for (let relayEvent of this.allRelayEvents) {
        if (relayEvent.athletes.length > 0) {
          this.eventRegistrationService.addRelayEvent(relayEvent);
        }
      }

      //this.successMessage = 'All registrations submitted successfully!';
      this.errorMessage = null;

      const allEvents = this.eventRegistrationService.getEvents();
      const allRelayEvents = this.eventRegistrationService.getRelayEvents();
      this.data.push({
        events: allEvents,
        relayEvents: allRelayEvents,
        registrar: this.registrar // Include registrar information
      });
      console.log(JSON.stringify(this.data));
      this.showCheckout = true;
      //this.resetForms();
    } catch (error) {
      this.errorMessage = 'An error occurred while submitting registrations. Please try again.';
      this.successMessage = null;
    }
  }

  getEnteredEvents(): Event[] {
    return this.allEvents.filter(event => event.athletes.length > 0);
  }

  getEnteredRelayEvents(): RelayEvent[] {
    return this.allRelayEvents.filter(event => event.athletes.length > 0);
  }

  hasEntries(): boolean {
    return this.getEnteredEvents().length > 0 || this.getEnteredRelayEvents().length > 0;
  }

  resetForms() {
    for (let event of this.allEvents) {
      event.athletes = [];
    }
    for (let relayEvent of this.allRelayEvents) {
      relayEvent.athletes = [];
      relayEvent.seedMark = '';
    }
    this.selectedEvent = null;
    this.selectedDate = null;
    this.filteredEvents = [];
    this.registeredAthletes = [];
  }

  openEventInfoDialog(): void {
    const dialogRef = this.dialog.open(EventInfoComponent, {
      width: '300px',
      maxHeight: '100vh',
      minWidth: '300px',
      data: {
        location: {
          name: 'Rutgers Camden',
          link: 'https://maps.app.goo.gl/sDDrwqL2S5vh1J26A'
        },
        timer: {
          name: 'Viper Timing',
          link: 'https://www.vipertiming.com/'
        }
      }
    });
  }

  onCheckoutCompleted(checkedout: boolean) {
    if (checkedout) {
      this.checkedout=true;
      console.log('Checkout completed:', checkedout);
      // Additional logic to run after checkout is completed
    }
  }
}

/*  registeredAthletes: RegisteredAthlete[] = [];
  selectedDate: Date | null = null;
  selectedMeet: string | null = null;
  filteredEvents: SubEventInfo[] = [];
  selectedEvent: SubEventInfo | null = null;
  filteredRelayEvents: RelayEvent[] = [];
  selectedRelayEvent: RelayEvent | null = null;
  availableDates: Date[] = [];
  groupedEventsByDate: { [date: string]: (Event | RelayEvent)[] } = {};
  editingAthleteIndex: number | null = null;
  editingAthlete: RegisteredAthlete | null = null;
  successMessage: string | null = null;
  errorMessage: string | null = null;
  data: any[] = []; // Add this property to hold the items for checkout
  showCheckout = false;
  register = false
  registrar = {
    name: '',
    email: '',
    phone: ''
  };
  checkedout=false;
  id="89fe5d60-985f-46fe-99c3-437100147765"
  type="";
  loading= false;
  seriesInfo!: SeriesInfo;
  eventInfo!: EventInfo[];
  seriesSponsor!: Sponsor;
  subEventInfo!: SubEventInfo[];
  event_id!: string[];
  registeredEvent: SubEventInfo[]=[]

  constructor(private readonly supabase: SupabaseService,private eventRegistrationService: EventRegistrationService, public dialog: MatDialog, private route: ActivatedRoute) { }

  async ngOnInit() {
   await this.getEventInfo();
   await this.getEvents();
   await this.getSubEvents();
    //this.initializeAvailableDates();
    //this.groupEventsByDate();
  }

  async getEventInfo(){
    this.route.params.subscribe(params => {
      //this.id = params['id'];
      this.type = this.route.snapshot.url[0].path === 'series' ? 'series' : 'event';
  });
  if (this.type === 'series') {
   await this.getSeriesInfo();
  } else {
    // Load event details using this.id
  }
}

  async getSeriesInfo(){
  try {
    this.loading = true
    const { data: seriesInfo, error, status } = await this.supabase.seriesInfo(this.id)
    console.log(seriesInfo)

    if (error && status !== 406) {
      throw error
    }

    if (seriesInfo) {
      this.seriesInfo = seriesInfo
      console.log(this.seriesInfo)
    }
  } catch (error) {
    if (error instanceof Error) {
      alert(error.message)
    }
  } finally {
    this.loading = false
  }

  try {
    this.loading = true
    const { data: sponsor, error, status } = await this.supabase.sponsorInfo(this.seriesInfo.sponsor_id)
    console.log(sponsor)

    if (error && status !== 406) {
      throw error
    }

    if (sponsor) {
      this.seriesSponsor = sponsor
      console.log(this.seriesSponsor)
    }
  } catch (error) {
    if (error instanceof Error) {
      alert(error.message)
    }
  } finally {
    this.loading = false
  }

  try {
    this.loading = true
    const { data: event_id, error, status } = await this.supabase.seriesEvents(this.id)
    console.log(event_id)

    if (error && status !== 406) {
      throw error
    }

    if (event_id) {
      this.event_id = event_id.map(event => event.id)
      console.log(this.event_id)
    }
  } catch (error) {
    if (error instanceof Error) {
      alert(error.message)
    }
  } finally {
    this.loading = false
  }
}

  async getEvents(){
  try {
    this.loading = true
    const { data: eventInfo, error, status } = await this.supabase.eventInfo(this.event_id)
    console.log("fuck",eventInfo)

    if (error && status !== 406) {
      throw error
    }

    if (eventInfo) {
      this.eventInfo = eventInfo
      console.log("helper",this.eventInfo)
    }
  } catch (error) {
    if (error instanceof Error) {
      alert(error.message)
    }
  } finally {
    this.loading = false
  }
}

async getSubEvents(){
  try {
    this.loading = true
    const { data: subEventInfo, error, status } = await this.supabase.subEvents(this.event_id)
    console.log(subEventInfo)

    if (error && status !== 406) {
      throw error
    }

    if (subEventInfo) {
      this.subEventInfo = subEventInfo
      console.log("sub",this.subEventInfo)
    }
  } catch (error) {
    if (error instanceof Error) {
      alert(error.message)
    }
  } finally {
    this.loading = false
  }
}

initializeAvailableDates() {
  const uniqueDates = new Set<string>();
  console.log("1")
  this.eventInfo.forEach(event => uniqueDates.add(new Date(event.start_date).toISOString()));
  console.log("2")

  this.availableDates = Array.from(uniqueDates).map(dateString => new Date(dateString));
  console.log("3")

  console.log("yo",this.availableDates)
}

  /*initializeAvailableDates() {
    const uniqueDates = new Set<string>();
    this.allEvents.forEach(event => uniqueDates.add(event.date.toISOString()));
    this.allRelayEvents.forEach(event => uniqueDates.add(event.date.toISOString()));
    this.availableDates = Array.from(uniqueDates).map(dateString => new Date(dateString));
  }*/

  /*groupEventsByDate() {
    this.groupedEventsByDate = {};
    this.allEvents.forEach(event => {
      const date = event.date.toDateString();
      if (!this.groupedEventsByDate[date]) {
        this.groupedEventsByDate[date] = [];
      }
      this.groupedEventsByDate[date].push(event);
    });
    this.allRelayEvents.forEach(relayEvent => {
      const date = relayEvent.date.toDateString();
      if (!this.groupedEventsByDate[date]) {
        this.groupedEventsByDate[date] = [];
      }
      this.groupedEventsByDate[date].push(relayEvent);
    });
  }*/


  /*onDateSelect(date: Date) {
    this.selectedEvent = null;
    this.filteredEvents = this.allEvents.filter(event => event.date.toDateString() === date.toDateString());
    this.selectedRelayEvent = null;
    this.filteredRelayEvents = this.allRelayEvents.filter(event => event.date.toDateString() === date.toDateString());
  }*/

  /*onMeetSelect(meetID: string) {
    this.selectedEvent = null;
    this.filteredEvents = this.subEventInfo.filter(event => event.id === meetID);
    //this.selectedRelayEvent = null;
    //this.filteredRelayEvents = this.allRelayEvents.filter(event => event.date.toDateString() === date.toDateString());
  }

  addAthlete() {
    this.registeredAthletes.push({ id: uuidv4(), name: '', dob: new Date(), gender: '', email: '', team: '', aau: '', usatf: '' });
  }

  deleteAthlete(index: number) {
    this.registeredAthletes.splice(index, 1);
  }

  deleteAssignedAthlete(event: SubEventInfo, index: number) {
    // Find the event in the registered events
    const existingEvent = this.registeredEvent.find(e => e.id === event.id);

    if (existingEvent && existingEvent.athletes && index >= 0 && index < existingEvent.athletes.length) {
        // Remove the athlete at the specified index
        existingEvent.athletes.splice(index, 1);
    }
}

  deleteAssignedRelayAthlete(event: RelayEvent, index: number) {
    event.athletes.splice(index, 1);
  }

  startEditAthlete(event: SubEventInfo | RelayEvent, athlete: RegisteredAthlete, index: number) {
    this.editingAthleteIndex = index;
    this.editingAthlete = { ...athlete };
  }

  updateAthlete() {
    if (this.editingAthleteIndex !== null && this.editingAthlete !== null) {
      const athleteIndex = this.editingAthleteIndex;
      const athlete = this.registeredAthletes[athleteIndex];
      Object.assign(athlete, this.editingAthlete);
      this.editingAthleteIndex = null;
      this.editingAthlete = null;
    }
  }

  cancelEdit() {
    this.editingAthleteIndex = null;
    this.editingAthlete = null;
  }

  onEventSelect(event: SubEventInfo) {
    this.selectedEvent = event
    this.selectedRelayEvent = null;
    console.log('Selected Event:', this.selectedEvent);
  }

  onRelayEventSelect(event: RelayEvent) {
    this.selectedRelayEvent = event;
    this.selectedEvent = null;
  }

  isAthleteDataComplete(athlete: RegisteredAthlete): boolean {
    console.log(!!athlete.name && !!athlete.dob && !!athlete.gender && !!athlete.email && !!athlete.team)
    return (!!athlete.name && !!athlete.dob && !!athlete.gender && !!athlete.email && !!athlete.team);
  }

  /*assignAthleteToEvent(event: SubEventInfo, athlete: RegisteredAthlete) {
    // Check if the event ID exists in the registered events
    const existingEvent = this.registeredEvent.find(e => e.id === event.id);

    // Add seedMark property to the athlete
    const athleteWithSeedMark = { ...athlete, seedMark: '' };

    if (existingEvent) {
        // If the event exists, add the athlete to the event's registered athletes
        existingEvent.athletes?.push(athleteWithSeedMark);
    } else {
        // If the event does not exist, add the new event with the athlete
        this.registeredEvent.push(event);
    }
}*/

/*assignAthleteToEvent(event: SubEventInfo, athlete: RegisteredAthlete) {
  if (!event.athletes) {
    event.athletes = [];
  }
  event.athletes.push({ ...athlete, seedMark: '' });
}

  /*assignAthleteToRelayEvent(event: RelayEvent, athlete: RegisteredAthlete) {
    if (event.athletes.length < 4 && !event.athletes.some(a => a.id === athlete.id)) {
      event.athletes.push({ ...athlete });
    } else {
      this.errorMessage = 'Each relay team must have exactly 4 different athletes.';
    }
  }*/

  /*isAthleteEligibleForRelay(event: RelayEvent, athlete: RegisteredAthlete): boolean {
    return event.athletes.length < 4 && !event.athletes.some(a => a.id === athlete.id);
  }

  isAthleteEligibleForEvent(event: SubEventInfo, athlete: RegisteredAthlete): boolean {
    return !this.registeredEvent.some(e => e.id === event.id && e.athletes?.some(a => a.id === athlete.id));
}

  isRegistrarInfoComplete(): boolean {
    return !!(this.registrar.name && this.registrar.email && this.registrar.phone);
  }

  onSubmit() {
    this.data = [];
    this.eventRegistrationService.clear();
    try {
      for (let event of this.allEvents) {
        if (event.athletes.length > 0) {
          this.eventRegistrationService.addEvent(event);
        }
      }

      for (let relayEvent of this.allRelayEvents) {
        if (relayEvent.athletes.length > 0) {
          this.eventRegistrationService.addRelayEvent(relayEvent);
        }
      }

      //this.successMessage = 'All registrations submitted successfully!';
      this.errorMessage = null;

      const allEvents = this.eventRegistrationService.getEvents();
      const allRelayEvents = this.eventRegistrationService.getRelayEvents();
      this.data.push({
        events: allEvents,
        relayEvents: allRelayEvents,
        registrar: this.registrar // Include registrar information
      });
      console.log(JSON.stringify(this.data));
      this.showCheckout = true;
      //this.resetForms();
    } catch (error) {
      this.errorMessage = 'An error occurred while submitting registrations. Please try again.';
      this.successMessage = null;
    }
  }

  getEnteredEvents(): SubEventInfo[] {
    return this.registeredEvent.filter(event => event.athletes!.length > 0);
  }

  /*getEnteredRelayEvents(): RelayEvent[] {
    return this.allRelayEvents.filter(event => event.athletes.length > 0);
  }*/

 /* hasEntries(): boolean {
    return this.getEnteredEvents().length > 0 /*|| this.getEnteredRelayEvents().length > 0*/;
  /*}

  resetForms() {
    for (let event of this.allEvents) {
      event.athletes = [];
    }
    for (let relayEvent of this.allRelayEvents) {
      relayEvent.athletes = [];
      relayEvent.seedMark = '';
    }
    this.selectedEvent = null;
    this.selectedDate = null;
    this.filteredEvents = [];
    this.registeredAthletes = [];
  }

  openEventInfoDialog(): void {
    const dialogRef = this.dialog.open(EventInfoComponent, {
      width: '300px',
      maxHeight: '100vh',
      minWidth: '300px',
      data: {
        location: {
          name: 'Kean Alumni Stadium',
          link: 'https://www.google.com/maps/place/Kean+Alumni+Stadium/@40.6810854,-74.2409794,16z/'
        },
        timer: {
          name: 'Viper Timing',
          link: 'https://www.vipertiming.com/'
        }
      }
    });
  }

  onCheckoutCompleted(checkedout: boolean) {
    if (checkedout) {
      this.checkedout=true;
      console.log('Checkout completed:', checkedout);
      // Additional logic to run after checkout is completed
    }
  }
}*/
