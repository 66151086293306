<div class="events-container">
  <h1>Upcoming Events</h1>

  <div class="card-container">
    <mat-card class="card">
      <mat-card-header class="card-header">
        <mat-card-title>More Events Coming Soon!</mat-card-title>
        <mat-card-subtitle>Make an account to stay up to date!</mat-card-subtitle>
      </mat-card-header>
      <div class="image-size">
        <img mat-card-image class="mosaicTwilight" src="../assets/images/mosaic-sports.png" alt="mosaic-sports">
      </div>
      <mat-card-content>
        <p></p>
      </mat-card-content>
      <mat-card-actions>
        <div class="event-button">
          <a class="register btn" mat-raised-button color="primary" href="profile/signup">Register</a>
        </div>
      </mat-card-actions>
    </mat-card>
  </div>
</div>
  <!--<div class="card-container">
    <mat-card class="card">
      <mat-card-header class="card-header">
        <mat-card-title>mosaic-sports Twilight</mat-card-title>
        <mat-card-subtitle>Starting Tuesday August 6th</mat-card-subtitle>
      </mat-card-header>
      <div class="image-size">
        <img mat-card-image class="mosaicTwilight" src="../assets/images/mosaic-sports Twilight.png" alt="Photo American Flag">
      </div>
      <mat-card-content>
        <p>Twilight Track and Field Camden, NJ</p>
      </mat-card-content>
      <mat-card-actions>
        <div class="event-button">
          <a class="register btn" mat-raised-button color="primary" href="series/1">mosaic-sports Twilight</a>
        </div>
      </mat-card-actions>
    </mat-card>

    <mat-card class="card">
      <mat-card-header class="card-header">
        <mat-card-title>RunningWorks</mat-card-title>
        <mat-card-subtitle>Sunday, August 11 – Friday, August 16, 2024</mat-card-subtitle>
      </mat-card-header>
      <div class="image-size">
        <img mat-card-image src="../assets/images/RunningWorks.webp" alt="Running Works Logo">
      </div>
      <mat-card-content>
        <p>Middle and High School Cross Country Camp</p>
      </mat-card-content>
      <mat-card-actions>
        <div class="event-button">
          <a class="register btn" mat-raised-button color="primary" href="http://www.runningworks.com" target="_blank" rel="noopener noreferrer">RunningWorks (External Link)</a>
        </div>
      </mat-card-actions>
    </mat-card>
  </div>-->
<!--<div class="events-container">
  <h1>Upcoming Events</h1>
  <div class="card-container">
    <mat-card class="card" appearance="outlined">
      <mat-card-header>
        <div mat-card-avatar class="example-header-image"></div>
        <mat-card-title>Great American Track & Field Series</mat-card-title>
        <mat-card-subtitle>By Coach Lisa Morgan</mat-card-subtitle>
      </mat-card-header>
      <div class="image-size">
        <img mat-card-image src="../assets/images/GATFS.png" alt="Photo American Flag">
      </div>
      <mat-card-content>
        <p>All Comers Track and Field Meet</p>
      </mat-card-content>
      <mat-card-actions>
        <div class="event-button">
          <a class="register" mat-raised-button color="primary" href="event/GATFS">Great American Track and Field Series</a>
        </div>
      </mat-card-actions>
    </mat-card>

    <mat-card class="card" appearance="outlined">
      <mat-card-header>
        <div mat-card-avatar class="example-header-image"></div>
        <mat-card-title>RunningWorks</mat-card-title>
        <mat-card-subtitle>Sunday, August 11 – Friday, August 16, 2024</mat-card-subtitle>
      </mat-card-header>
      <div class="image-size">
        <img mat-card-image src="../assets/images/RunningWorks.webp" alt="Photo American Flag">
      </div>
      <mat-card-content>
        <p>Middle School and High School Cross Country Camp</p>
      </mat-card-content>
      <mat-card-actions>
        <div class="event-button">
          <a class="register" mat-raised-button color="primary" href="event/GATFS">Running Works(External Link)</a>
        </div>
      </mat-card-actions>
    </mat-card>
  </div>
</div>-->
