import { Injectable } from '@angular/core';
import { SupabaseClient, User } from '@supabase/supabase-js';
import { SupabaseService } from '../supabase/supabase.service';
import { Athlete, Social, CardData, CardDisplay, TeamData, Competitors } from '../../models/athlete/athlete.model';


@Injectable({
  providedIn: 'root',
})
export class AthleteService {
  private supabase: SupabaseClient;

  constructor(private supabaseService: SupabaseService) {
    this.supabase = this.supabaseService.getClient();
  }


  // Fetch athlete data
  getAthlete(userId: string) {
    return this.supabase
      .from('athlete_data')
      .select('name, nickname, strava, world_athletics, milesplit, tfrrs, instagram')
      .eq('id', userId)
      .returns<Athlete>()
      .single();
  }

  getSocial(user: User) {
    return this.supabase
      .from('athlete_data')
      .select(`strava, world_athletics, milesplit, tfrrs, instagram`)
      .eq('id', user.id)
      .returns<Social>()
      .single()
  }

  getCardData(user: User) {
    return this.supabase
      .from('athlete_data')
      .select('name, pronunciation, nickname, line1, line2, notes')
      .eq('id', user.id)
      .returns<CardData>()
      .single()
  }

  getTeamData(user: User) {
    return this.supabase
      .from('athlete_data')
      .select('team_name, team_mascot, coach, event')
      .eq('id', user.id)
      .returns<TeamData>()
      .single()
  }

  getCardDisplay(userID: string) {
    return this.supabase
      .from('athlete_data')
      .select('name, pronunciation, nickname, line1, line2, notes, team_name, team_mascot, coach, imageUrl')
      .eq('id', userID)
      .returns<CardDisplay>()
      .single()
  }

  getCardTempDisplay(tempID: string) {
    return this.supabase
      .from('nonuser_athlete_data')
      .select('name, pronunciation, nickname, line1, line2, notes, team_name, team_mascot, coach')
      .eq('id', tempID)
      .returns<CardDisplay>()
      .single()
  }

  async updateCardPhotoUrl(userId: string, imageUrl: string) {
    // Assuming you have a Supabase client initialized
    const { data, error } = await this.supabase
      .from('athlete_data') // Replace with your table name
      .update({ imageUrl }) // Replace with your column name
      .eq('id', userId);
    
    return { data, error };
  }


  // Update social links
  updateSocial(social: Social) {
    const updatedSocial = {
      ...social,
      updated_at: new Date(),
    };

    return this.supabase.from('athlete_data').upsert(updatedSocial);
  }

  updateTeamData(teamData: TeamData) {
    const updateTeamData = {
      ...teamData,
      updated_at: new Date(),
    }

    return this.supabase.from('athlete_data').upsert(updateTeamData)
  }

  // Update card data
  updateCardData(cardData: CardData) {
    const updatedCardData = {
      ...cardData,
      updated_at: new Date(),
    };

    return this.supabase.from('athlete_data').upsert(updatedCardData);
  }

  // Update card display data
  updateCardDisplayData(cardDisplay: CardDisplay) {
    const updatedCardDisplay = {
      ...cardDisplay,
      updated_at: new Date(),
    };

    return this.supabase.from('athlete_data').upsert(updatedCardDisplay);
  }

  updateCardDataFromCard(user: boolean, idNum: string, value: number, newValue: string) {//0 name 1 pronunciation 2 nickname 3 line1 4 line2 5 team 6 mascot 7 coach 8 cardback
    var update
    var table = ""
    if (user) {
      table = "athlete_data"
    } else {
      table = "nonuser_athlete_data"
    }
    if (value == 0) {
      update = {
        id: idNum,
        name: newValue,
        updated_at: new Date()
      }
    }

    if (value == 1) {
      update = {
        id: idNum,
        pronunciation: newValue,
        updated_at: new Date()
      }
    }

    if (value == 2) {
      update = {
        id: idNum,
        nickname: newValue,
        updated_at: new Date()
      }
    }

    if (value == 3) {
      update = {
        id: idNum,
        line1: newValue,
        updated_at: new Date()
      }
    }

    if (value == 4) {
      update = {
        id: idNum,
        line2: newValue,
        updated_at: new Date()
      }
    }

    if (value == 5) {
      update = {
        id: idNum,
        team_name: newValue,
        updated_at: new Date()
      }
    }

    if (value == 6) {
      update = {
        id: idNum,
        team_mascot: newValue,
        updated_at: new Date()
      }
    }

    if (value == 7) {
      update = {
        id: idNum,
        coach: newValue,
        updated_at: new Date()
      }
    }

    if (value == 8) {
      update = {
        id: idNum,
        notes: newValue,
        updated_at: new Date()
      }
    }

    return this.supabase.from(table).upsert(update)
  }

  // Generate temporary athlete
  generateTempAthlete(cardDisplay: CardDisplay) {
    const newAthlete = {
      ...cardDisplay,
      updated_at: new Date(),
    };

    return this.supabase
      .from('nonuser_athlete_data')
      .insert(newAthlete)
      .select('id')
      .single();
  }

  // Add competitor
  addCompetitor(competitor: Competitors) {
    const newCompetitor = {
      ...competitor,
      updated_at: new Date(),
    };

    return this.supabase.from('competitors').insert(newCompetitor);
  }

  viewAllUsers() {
    return this.supabase
      .from('athlete_data')
      .select('id, event')
      .order('event', { ascending: true })
  }

  // Additional athlete-related methods...
}