<div #page>
@if(!showCheckout) {
<div class="ticketing-container">
    <h2>Buy Tickets for USATF Region 2</h2>
    
    <div *ngFor="let option of ticketOptions" class="ticket-option">
      <mat-form-field appearance="fill">
        <mat-label>{{option.day}} Tickets (${{prices.singleDay}} each + ${{fees.singleDay}} fee)</mat-label>
        <mat-select [(ngModel)]="option.selected" (selectionChange)="updateTicket(option, option.selected)">
          <mat-option *ngFor="let i of [].constructor(11); let idx = index" [value]="idx">{{idx}}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    
    <div class="ticket-option">
      <mat-form-field appearance="fill">
        <mat-label>Multiday Pass (${{prices.multiDay}} + ${{fees.multiDay}} fee)</mat-label>
        <mat-select [(ngModel)]="multiDayPass.selected" (selectionChange)="updateTicket(multiDayPass, multiDayPass.selected)">
          <mat-option *ngFor="let i of [].constructor(11); let idx = index" [value]="idx">{{idx}}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    
    <div class="total-tickets">
      <p>Total Tickets: {{ totalTickets }}</p>
    </div>
    
    <div class="total-cost">
      <p>Total Cost: ${{ totalCost.toFixed(2) }}</p>
    </div>
    <!-- Registrar Information Form -->
   <h2 class="section-title">Purchaser Information</h2>
   <p class="description">Must match the ID at pick up!</p>
   <div class="form-container">
       <div class="registrar-container">
           <div>
               <mat-form-field>
                   <mat-label>Name</mat-label>
                   <input matInput type="text" [(ngModel)]="registrar.name" name="registrarName" required>
               </mat-form-field>
               <mat-form-field>
                   <mat-label>Email</mat-label>
                   <input matInput type="email" [(ngModel)]="registrar.email" name="registrarEmail" required>
               </mat-form-field>
               <mat-form-field>
                   <mat-label>Phone Number</mat-label>
                   <input matInput type="tel" [(ngModel)]="registrar.phone" name="registrarPhone" required>
               </mat-form-field>
           </div>
       </div>
   </div>
    <button mat-raised-button color="primary" (click)="buyTickets()" *ngIf="isRegistrarInfoComplete()">Buy Tickets </button>
  </div>
   

}@else if (showCheckout) {
    @if(!checkedout){
<div class="back-button-container">
    <button mat-icon-button (click)="showCheckout = false; page.scrollTop" class="back-button">
        <mat-icon class="back">undo</mat-icon>
    </button>
    <p class="warning-message">Do Not Use Your Browser's Back Button!</p>
</div>
}@else {
<div class="back-button-container">
<p class="warning-message">Your Tickets Will Be Emailed to You!</p>
</div>
}
<app-embedded-ticket-checkout [data]="data" (checkedoutChange)="onCheckoutCompleted($event)"></app-embedded-ticket-checkout>
}
</div>