import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, map, of, switchMap, tap, throwError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SpotifyService {
  private apiUrl = 'https://api.spotify.com/v1';
  private clientId = '46c8d5e8c8544075a1460e41c3da53c5';
  private clientSecret = '6cf843eecff94c26b2d08dc66ebae594';
  private accessToken = '';

  constructor(private http: HttpClient) {}

  // Method to get access token
  private getAccessToken(): Observable<string> {
    if (this.accessToken) {
      // Return the token if it's already available
      return of(this.accessToken);
    }
    
    const body = new HttpParams()
      .set('grant_type', 'client_credentials')
      .set('client_id', this.clientId)
      .set('client_secret', this.clientSecret);

    return this.http.post<any>('https://accounts.spotify.com/api/token', body.toString(), {
      headers: new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded'
      })
    }).pipe(
      map(response => response.access_token),
      tap(token => this.accessToken = token),
      catchError(error => {
        console.error('Error fetching access token', error);
        return throwError(() => new Error('Failed to fetch access token'));
      })
    );
  }

  // Method to search for songs
  searchSongs(query: string): Observable<any> {
    return this.getAccessToken().pipe(
      switchMap(token => {
        const headers = new HttpHeaders({
          'Authorization': `Bearer ${token}`
        });

        const params = new HttpParams()
          .set('q', query)
          .set('type', 'track');

        return this.http.get<any>(`${this.apiUrl}/search`, { headers, params });
      }),
      catchError(error => {
        console.error('Error searching songs', error);
        return throwError(() => new Error('Failed to search songs'));
      })
    );
  }

}