import { Injectable } from '@angular/core';
import { Event, RelayEvent } from '../models/event.model';

@Injectable({
  providedIn: 'root'
})
export class EventRegistrationService {

  private events: Event[] = [];
  private relayEvents: RelayEvent[] = [];

  constructor() { }

  addEvent(event: Event) {
    this.events.push(event);
  }

  addRelayEvent(event: RelayEvent) {
    this.relayEvents.push(event);
  }

  getEvents(): Event[] {
    return this.events;
  }

  getRelayEvents(): RelayEvent[] {
    return this.relayEvents;
  }
  clear(){
    this.events=[]
    this.relayEvents=[]
  }
}