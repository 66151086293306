@if (!accessGranted) {
<div>
  <h2>Enter Password</h2>
  <mat-form-field appearance="fill">
    <mat-label>Password</mat-label>
    <input matInput [(ngModel)]="enteredPassword" type="password">
  </mat-form-field>
  <button mat-raised-button color="primary" (click)="checkPassword()">Submit</button>
  <div *ngIf="errorMessage" class="error-message">
    {{ errorMessage }}
  </div>
</div>
}@else if(accessGranted){

<div>
    <h2>Select a Date to Download Hytek Data</h2>
    <mat-form-field appearance="fill">
      <mat-label>Select Date</mat-label>
      <mat-select [(ngModel)]="selectedDate" (selectionChange)="onDateChange()">
        <mat-option *ngFor="let date of availableDates" [value]="date">{{ date | date: 'fullDate' }}</mat-option>
      </mat-select>
    </mat-form-field>
    <!--<div>{{events | json}}</div>-->
    <button mat-raised-button color="primary" (click)="downloadDelimitedData()">Download Hytek Data</button>
  
    <div *ngIf="errorMessage" class="error-message">
      {{ errorMessage }}
    </div>
   
    <div *ngIf="selectedDate">
      <h3>Athletes for {{ selectedDate | date: 'fullDate' }}</h3>
      <table>
        <thead>
          <tr>
            <th>Athlete</th>
            <th>Team</th>
            <th>DOB</th>
            <th>Events</th>
            <th>Payment Status</th>
            <th>Email</th>
            <th>Phone</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let athlete of sortedAthletes">
            <td>{{ athlete.name }}</td>
            <td>{{ athlete.team }}</td>
            <td>{{ athlete.dob }}</td>
            <td>
              <ul>
                <li *ngFor="let event of athlete.events">{{ event.name }} {{ event.seedMark }}</li>
              </ul>
            </td>
            <td>
              {{ athlete.paid ? 'Paid by ' + athlete.payerName : 'Not Paid Find ' + athlete.payerName}}
            </td>
            <td>{{ athlete.email }} {{athlete.payerEmail}}</td>
            <td>{{ athlete.phone }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
}